import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";
import { Html5Entities } from "html-entities";
import MatchingBox from "./MatchingBox";
import { v4 as uuidv4 } from "uuid";

export const MatchingContext = React.createContext({});

const decode = new Html5Entities().decode;

class Matching extends React.Component {
  constructor(props) {
    super(props);
    this.debounceFn = null;
    this.state = {
      marks: this.props.marks, // NOTE: if we have 3 statement then user answered only 2 of them. Then this array only contain 2 mark.
      markUUID: uuidv4(), // this is fastest solution for now (deadline) to re-mount <MatchingBox /> with fresh prop changes
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.marks !== this.state.marks) {
      if (!this.debounceFn) {
        this.debounceFn = debounce(
          () => {
            this.props.saveAnswer(
              this.props.question.id,
              this.state.marks.length === 0 ? null : this.state.marks
            );
          },
          2000,
          { leading: false, trailing: true }
        );
      }
      this.debounceFn();
    }
  }

  handleSelect = ({ statementId, choiceId }) => {
    this.setState((state) => {
      let resetMarks = state.marks.filter(
        (mark) => mark.statement_id !== statementId
      );
      return {
        ...state,
        marks: [
          ...resetMarks,
          { statement_id: statementId, answer_id: choiceId },
        ],
      };
    });
  };

  cancelOneAnswer = (id) => {
    this.setState((state) => ({
      ...state,
      marks: state.marks.filter((mark) => mark.answer_id !== id),
    }));
  };

  cancelAnswer = () => {
    this.setState({ marks: [], markUUID: uuidv4() });
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    const { question } = this.props;
    const { marks } = this.state;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: decode(question.content),
            }}
          />
        </QuestionContentContainer>

        <AnswerContainer className="text-dark" style={{ paddingLeft: 0 }}>
          <MatchingContext.Provider
            value={{
              handleSelect: this.handleSelect,
              cancelOneAnswer: this.cancelOneAnswer,
            }}
          >
            <MatchingBox
              key={this.state.markUUID}
              statements={question.statements.map((stmt) => {
                const markCurrentStatement = marks.find(
                  ({ statement_id }) => statement_id === stmt.id
                );
                return {
                  id: stmt.id,
                  content: decode(stmt.content),
                  choice: markCurrentStatement
                    ? question.answers.find(
                        ({ id }) => id === markCurrentStatement.answer_id
                      )
                    : null,
                };
              })}
              availableChoices={question.answers
                .filter(
                  ({ id }) => !marks.some(({ answer_id }) => answer_id === id)
                )
                .map((answer) => ({
                  id: answer.id,
                  content: decode(answer.content),
                }))}
            />
          </MatchingContext.Provider>
        </AnswerContainer>

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Matching.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  marks:
    state.accomplish.studentAnswers[ownProps.question.id].question_answer || [],
});

const MatchingRedux = connect(mapStateToProps)(Matching);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MatchingRedux {...props} />
  </ErrorBoundary>
);
