import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const BadgePill = ({ variant, children, className = null, ...rest }) => (
  <span
    className={cx(
      "badge",
      "badge-pill",
      variant ? "badge-" + variant : null,
      className,
    )}
    {...rest}
  >
    {children}
  </span>
);

BadgePill.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    "light",
    "lighter",
    "gray",
    "dark",
    "danger",
    "success",
    "warning",
    "info",
    "outline-primary",
    "outline-secondary",
    "outline-success",
    "outline-info",
    "outline-warning",
    "outline-danger",
    "outline-dark",
  ]).isRequired,
};

export default BadgePill;
