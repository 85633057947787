import api from "api";
import {
  ACCOMPLISH_RESUMED,
  ACCOMPLISH_RESUME_REJECTED,
  ACCOMPLISH_STARTED,
  ACCOMPLISH_ENDED,
  ACCOMPLISH_EXITED,
  ACCOMPLISH_ANSWER_SAVED,
  ACCOMPLISH_ANSWER_SAVED_IN_SERVER,
  ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER,
  ACCOMPLISH_QUESTION_CHANGED,
} from "./type";

export const questionChanged = (question) => ({
  type: ACCOMPLISH_QUESTION_CHANGED,
  question,
});

export const accomplishAnswerSavedInServer = (questionId) => ({
  type: ACCOMPLISH_ANSWER_SAVED_IN_SERVER,
  questionId,
});

export const accomplishAnswerFailedSaveInServer = (questionId) => ({
  type: ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER,
  questionId,
});

export const accomplishAnswerSaved = (answer) => ({
  type: ACCOMPLISH_ANSWER_SAVED,
  answer,
});

export const accomplishResumed = (data) => ({
  type: ACCOMPLISH_RESUMED,
  data,
});

export const accomplishResumeRejected = () => ({
  type: ACCOMPLISH_RESUME_REJECTED,
});

export const accomplishStarted = (data) => ({
  type: ACCOMPLISH_STARTED,
  data,
});

export const accomplishEnded = () => ({
  type: ACCOMPLISH_ENDED,
});

export const accomplishExited = () => ({
  type: ACCOMPLISH_EXITED,
});

export const changeQuestion = (question) => {
  return (dispatch) => dispatch(questionChanged(question));
};

export const saveAnswer = (payload) => {
  return (dispatch) => {
    dispatch(accomplishAnswerSaved(payload.answer));

    const questionId = payload.answer.question_id;
    return api.accomplish
      .saveAnswer(payload)
      .then((res) => {
        dispatch(accomplishAnswerSavedInServer(questionId));
        return res;
      })
      .catch((err) => {
        dispatch(accomplishAnswerFailedSaveInServer(questionId));
        throw err;
      });
  };
};

export const resumeAccomplish = (examId, accomplishId) => {
  return (dispatch) =>
    api.accomplish.resume(examId, accomplishId).then((accomplish) => {
      if (accomplish.status === "finished" && accomplish.finished_at !== null) {
        dispatch(accomplishResumeRejected());
        throw {
          accomplishAlreadySubmitted: true,
          accomplish,
        };
      }

      dispatch(accomplishResumed(accomplish));
    });
};

export const startAccomplish = (examId, token) => {
  return (dispatch) =>
    api.accomplish.start(examId, token).then((accomplish) => {
      dispatch(accomplishStarted(accomplish));
      return accomplish;
    });
};

export const endAccomplish = (accomplish, studentAnswers) => {
  return (dispatch) =>
    api.accomplish
      .submit(accomplish.exam.id, accomplish.id, studentAnswers)
      .then(() => dispatch(accomplishEnded()));
};

export const exitAccomplish = () => (dispatch) => dispatch(accomplishExited());
