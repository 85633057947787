import flatten from "lodash/flatten";

const InitAnswersSavedInServerState = (sections) => {
  return flatten(sections.map((section) => section.questions)).reduce(
    (accumulator, question) => {
      return {
        ...accumulator,
        [question.id]: true,
      };
    },
    {}
  );
}

export default InitAnswersSavedInServerState;
