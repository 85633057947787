import React from "react";
import HeadingMenu from "./HeadingMenu";
import ItemMenu from "./ItemMenu";
import DropdownMenu from "./DropdownMenu";
import BaseBadge from "components/atoms/Badge/BaseBadge";

// export default (props) => (
//   <ul className="nk-menu">
//     <HeadingMenu text="Menu" />
//     <ItemMenu icon="dashboard" link="http://edulogy.id" text="Dashboard" />
//     <ItemMenu
//       icon="file-text"
//       link="http://edulogy.id"
//       text="My Subscription"
//     />
//     <ItemMenu
//       icon="report-profit"
//       link="http://edulogy.id"
//       text="Payment History"
//     />
//     <ItemMenu icon="download-cloud" link="http://edulogy.id" text="Download" />
//     <ItemMenu
//       icon="account-setting"
//       link="http://edulogy.id"
//       text="Account Setting"
//     />

//     <HeadingMenu text="See Others" />
//     <DropdownMenu icon="files" text="Pre-Built Pages">
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Pricing"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Team Access"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Team Access"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Login Activity"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Invoices"
//         count={2}
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="View Invoice"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Tickets"
//         count={5}
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Ticket Details"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Support"
//       />
//       <ItemMenu
//         icon="account-setting"
//         link="http://edulogy.id"
//         text="Subscription Details"
//       />
//     </DropdownMenu>
//   </ul>
// );

const NewBadge = () => <BaseBadge variant="danger">Baru</BaseBadge>;

export default (props) => (
  <ul className="nk-menu">
    <HeadingMenu text="Menu" />
    <ItemMenu icon="files" link="/home" text="Ujian" />
    <ItemMenu icon="box" link="/package" text={<span>Paket <NewBadge /></span>} />
    <ItemMenu icon="tag" link="/transaction" text={<span>Transaksi <NewBadge /></span>} />
  </ul>
);
