import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Alert from "components/atoms/Alert/Alert";
import { Link } from "react-router-dom";
import NioIcon from "components/atoms/Icon/NioIcon";
import { useSelector } from "react-redux";

export const MobileMenuContext = React.createContext({});

const Subscription = (props) => {
  const [isMobileMenuShow, setIsMobileMenuShow] = React.useState(false);
  const [isGoogleNotConnected, isPhoneEmpty] = useSelector((state) => [
    !state.auth.student.has_google_account,
    !state.auth.student.phone,
  ]);

  return (
    <MobileMenuContext.Provider
      value={{
        isShow: isMobileMenuShow,
        toggle: () => setIsMobileMenuShow(!isMobileMenuShow),
        close: () => setIsMobileMenuShow(false),
      }}
    >
      <div className="nk-app-root nk-body npc-subscription has-aside ui-clean no-touch nk-nio-theme">
        <div className="nk-main">
          <div className="nk-wrap">
            <Header />

            {(isGoogleNotConnected || isPhoneEmpty) && (
              <div className="nk-content">
                {isGoogleNotConnected && (
                  <Alert variant="info" className="mb-1">
                    <NioIcon name="google" /> Akun kamu belum terhubung dengan
                    Google. Pengaturan tersebut ada di{" "}
                    <Link to="/user" className="text-primary">
                      akun
                    </Link>
                    .
                  </Alert>
                )}
                {isPhoneEmpty && (
                  <Alert variant="info" className="mb-1">
                    <NioIcon name="call-fill" /> Kamu belum mengatur nomor
                    telepon. Pengaturan tersebut ada di{" "}
                    <Link to="/user" className="text-primary">
                      akun
                    </Link>
                    .
                  </Alert>
                )}
              </div>
            )}

            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  <SideMenu />

                  <div className="nk-content-body">
                    <div className="nk-content-wrap">
                      {props.children} {/* CONTENT WILL BE PLACED HERE  */}
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MobileMenuContext.Provider>
  );
};

Subscription.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default Subscription;
