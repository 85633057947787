import React from "react";
import PropTypes from "prop-types";
import renderMath from "domain/Math/MathJax";
import Multichoice from "./Multichoice";
import Multianswer from "./Multianswer";
import Truefalse from "./Truefalse";
import Essay from "./Essay";
import Attachments from "./shared/Attachments";
import FailAlertWhenSaveAnswer from "./FailAlertWhenSaveAnswer";
import values from "lodash/values";
import { connect } from "react-redux";
import { saveAnswer } from "redux/accomplish/action";
import Toastr from "components/Notification/Toastr";
import moment from "moment-timezone";
import Shortanswer from "./Shortanswer";
import Matching from "./Matching";

const onSaveToastrItem = (
  <Toastr.Item variant="info" icon="check-circle-fill">
    [{moment().format("HH:mm:ss")}] Sedang menyimpan...
  </Toastr.Item>
);
const successedToastrItem = (
  <Toastr.Item
    variant="success"
    icon="check-circle-fill"
    className="bg-success-dim"
  >
    [{moment().format("HH:mm:ss")}] Berhasil menyimpan
  </Toastr.Item>
);
const failedToastrItem = (
  <Toastr.Item variant="error" icon="info-fill" className="bg-danger-dim">
    [{moment().format("HH:mm:ss")}] Gagal menyimpan
  </Toastr.Item>
);

class index extends React.Component {
  static contextType = Toastr.Context;

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  componentDidMount() {
    renderMath(() => this.node.current);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      renderMath(() => this.node.current);
    }
  }

  // NOTE: questionId perlu karena mungkin saja dia pakai debounce
  // sehingga questionId bisa salah ketika pindah soal secara cepat
  saveAnswer = (questionId, answer) => {
    this.context.toastrAddItem(onSaveToastrItem);
    this.sendAnswerToServer(questionId, answer)
      .then(() => this.context.toastrAddItem(successedToastrItem))
      .catch(() => this.context.toastrAddItem(failedToastrItem));
  };

  saveUnsavedAnswers = () => {
    return Promise.all(
      this.props.unsavedStudentAnswers.map((studentAnswer) =>
        this.sendAnswerToServer(
          studentAnswer.question_id,
          studentAnswer.question_answer
        )
      )
    );
  };

  sendAnswerToServer = (questionId, questionAnswer) => {
    return this.props.saveAnswer({
      examId: this.props.examId,
      accomplishId: this.props.accomplishId,
      answer: {
        question_id: questionId,
        question_answer: questionAnswer,
      },
    }).catch((err) => {
      if (err.response && err.response.status === 403) {
        this.props.submit();
        return;
        }
        throw err;
      });
  };

  render() {
    const { question } = this.props;

    return (
      <div className="card-inner card-inner-lg" ref={this.node}>
        <div className="align-center flex-wrap flex-md-nowrap g-3 h-100">
          <div
            className="nk-block-content"
            style={{ maxWidth: "100%", minWidth: "80%" }}
          >
            {this.props.unsavedStudentAnswers.length > 0 && (
              <FailAlertWhenSaveAnswer
                total={this.props.unsavedStudentAnswers.length}
                onResend={this.saveUnsavedAnswers}
              />
            )}

            {question.attachments.length > 0 && (
              <Attachments data={question.attachments} />
            )}

            {question.type === "multichoice" && (
              <Multichoice question={question} saveAnswer={this.saveAnswer} />
            )}
            {question.type === "multianswer" && (
              <Multianswer question={question} saveAnswer={this.saveAnswer} />
            )}
            {question.type === "truefalse" && (
              <Truefalse question={question} saveAnswer={this.saveAnswer} />
            )}
            {question.type === "essay" && (
              <Essay
                key={question.id}
                question={question}
                saveAnswer={this.saveAnswer}
              />
            )}
            {question.type === "shortanswer" && (
              <Shortanswer
                key={question.id}
                question={question}
                saveAnswer={this.saveAnswer}
              />
            )}
            {question.type === "matching" && (
              <Matching
                key={question.id}
                question={question}
                saveAnswer={this.saveAnswer}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

index.propTypes = {};

const mapStateToProps = (state) => ({
  unsavedStudentAnswers: values(state.accomplish.studentAnswers).filter(
    (studentAnswer) => {
      return (
        state.accomplish.isAnswersSavedInServer[studentAnswer.question_id] ===
        false
      );
    }
  ),
  examId: state.accomplish.data.school_exam_id,
  accomplishId: state.accomplish.data.id,
});

export default connect(mapStateToProps, { saveAnswer })(index);
