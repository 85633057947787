import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export default [
  rest.get(`${BASE_URL}/v1/my/exam-package`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: [
          {
            id: "1",
            name: "Paket CPNS 2020",
          },
          {
            id: "2",
            name: "Paket CPNS 2020 #2",
          },
        ],
      })
    );
  }),
];
