import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Block({ children, className = "", ...restProps }) {
  return (
    <div className={cx("nk-block", className)} {...restProps}>
      {children}
    </div>
  );
}

Block.Between = ({ children, size = "md", className = "", ...restProps }) => {
  return (
    <div className={cx("nk-block-between-" + size, className)} {...restProps}>
      {children}
    </div>
  );
};

Block.Head = ({ children, size = "md", className = "", ...restProps }) => {
  return (
    <div
      className={cx("nk-block-head", "nk-block-head-" + size, className)}
      {...restProps}
    >
      {children}
    </div>
  );
};

Block.Head.Content = ({ children, className = "", ...restProps }) => {
  return (
    <div className={cx("nk-block-head-content", className)} {...restProps}>
      {children}
    </div>
  );
};

Block.Head.Content.Sub = ({ children, className = "", ...restProps }) => {
  return (
    <div className={cx("nk-block-head-sub", className)} {...restProps}>
      {children}
    </div>
  );
};

Block.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};

Block.Between.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Classname
   */
  className: PropTypes.string,
  /**
   * Pre-defined size
   */
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
};

Block.Head.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Classname
   */
  className: PropTypes.string,
};

Block.Head.Content.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};

Block.Head.Content.Sub.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
};
