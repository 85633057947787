import React from "react";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import IconButton from "components/molecules/Button/IconButton";
import userAPI from "api/user";
import Alert from "components/atoms/Alert/Alert";
import { loginGoogle } from "redux/auth/action";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { queryParams } from "renames";
import { useReducer } from "react";
import { getGoogleErrorMessage } from "utils/misc";
import { ErrorReport } from "domain/Error/ErrorReport";

const DEFAULT_ERROR_MESSAGE = "Gagal terhubung dengan Google.";

const ACTION = {
  FAILED: "FAILED",
  RESET: "",
};
const INITIAL_STATE = {
  isFailed: false,
  message: null,
};
function reducer(state, action) {
  switch (action.type) {
    case ACTION.FAILED:
      return {
        ...state,
        isFailed: true,
        message: action.message || DEFAULT_ERROR_MESSAGE,
      };
    case ACTION.RESET:
      return { ...INITIAL_STATE };
    default:
      throw new Error("Unhandle action type: " + action.type);
  }
}

export default function GoogleButton(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [failState, dispatchFail] = useReducer(reducer, INITIAL_STATE);

  function handleConnect(googleUser) {
    dispatchFail({ type: ACTION.RESET });
    userAPI
      .loginGoogle({ google_token: googleUser.getAuthResponse().id_token })
      .then((auth) => {
        dispatch(loginGoogle(auth));
        const targetURL = decodeURIComponent(
          queryParams.parse(location.search).to || ""
        );
        if (targetURL) {
          history.push(targetURL);
        } else {
          history.push("/home");
        }
      })
      .catch((err) => {
        ErrorReport(err);
        dispatchFail({ type: ACTION.FAILED });
      });
  }

  function handleConnectFailed(res) {
    if (!res.error) {
      throw res;
    }
    if (res.error === "popup_closed_by_user") {
      return;
    }
    dispatchFail({
      type: ACTION.FAILED,
      message: getGoogleErrorMessage(res, DEFAULT_ERROR_MESSAGE),
    });
  }

  return (
    <>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <IconButton
            iconLeft="google"
            variant="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            block
          >
            Daftar dengan Google
          </IconButton>
        )}
        buttonText="Login"
        onSuccess={handleConnect}
        onFailure={handleConnectFailed}
        cookiePolicy={"single_host_origin"}
      />

      {failState.isFailed && (
        <Alert variant="warning" className="mt-2">
          {failState.message}
        </Alert>
      )}
    </>
  );
}

GoogleButton.propTypes = {};
