import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert/Alert";
import Log from "domain/Log/Log";

export default ({ url, extension }) => {
  const sources = {
    mp4: <source src={url} type="video/mp4" />,
    webm: <source src={url} type="video/webm" />,
    ogg: <source src={url} type="video/ogg" />,
  };

  return (
    <video autoplay style={{ width: "100%" }} controls>
      {sources[extension] || (
        <Alert warning>
          Browser kamu tidak mendukung pemutar video. Sebagai gantinya klik
          tombol berikut untuk mengunduh file video: <br />
          <a href={url} target="_blank" className="btn btn-primary" download>
            <em className="ni ni-download"></em> Unduh
          </a>
        </Alert>
      )}
    </video>
  );
};
