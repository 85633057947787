import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "../variables";

export const SCHOOL_TYPES = [
  {
    id: "school",
    name: "Sekolah",
  },
  {
    id: "college",
    name: "Perguruan Tinggi",
  },
  {
    id: "course",
    name: "Bimbingan Belajar",
  },
  {
    id: "boarding_school",
    name: "Sekolah Bersama",
  },
];

export default [
  rest.get(`${BASE_URL}/v1/master/school-type`, (req, res, ctx) => {
    return res(
      ctx.delay(200),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: SCHOOL_TYPES,
      })
    );
  }),
];
