import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { connect } from "react-redux";
import { hide404Popup } from "redux/page/action";
import { Link, withRouter } from "react-router-dom";
import Row from "components/atoms/Grid/Row";
import Col from "components/atoms/Grid/Col";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

class EndpointNotFoundError extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    return (
      <ModalPortal>
        <Container>
          <div className="nk-content ">
            <div className="nk-block nk-block-middle wide-xs mx-auto">
              <div className="nk-block-content nk-error-ld text-center">
                <h1 className="nk-error-head">404</h1>
                <h3 className="nk-error-title">Data tidak ditemukan</h3>
                <p className="nk-error-text">
                  Tampaknya kamu mengakses data yang sudah dihapus atau tidak
                  pernah ada sebelumnya.
                </p>
                <Row>
                  <Col size={6}>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary btn-block mt-2"
                      onClick={() => {
                        this.props.hide404Popup();
                        this.props.history.push("/home");
                      }}
                    >
                      Kembali ke Beranda
                    </button>
                  </Col>
                  <Col size={6}>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary btn-block mt-2"
                      onClick={() => this.props.hide404Popup()}
                    >
                      Tutup Pesan
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

EndpointNotFoundError.propTypes = {};

export default connect(null, { hide404Popup })(
  withRouter(EndpointNotFoundError)
);
