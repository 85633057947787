import flatten from "lodash/flatten";
import { Html5Entities } from "html-entities";

/**
 * Digunakan untuk mengambil "konten" jawaban dari object
 * jawaban.
 *
 * misal, response dari server sbb:
 * {
 *    multichoice: {
 *      "mark": {
 *        "id": 472,
 *        "answer_id": 4020,
 *        "created_at": "2020-08-27T02:29:12.000Z",
 *        "updated_at": "2020-08-27T02:29:15.000Z",
 *        "student_se_sect_q_multichoice_id": 1357
 *      }
 *    }
 * }
 *
 * Aplikasi hanya butuh answer_id maka fungsi-fungsi disini
 * seharusnya mengolah object di atas menjadi konten jawaban,
 * yaitu 4020.
 */

const extractMultichoice = (question) => {
  if (!question.multichoice.mark) return null;

  return question.multichoice.mark.answer_id;
};

const extractMultianswer = (question) => {
  if (!Array.isArray(question.multianswer.marks)) return null;
  if (question.multianswer.marks.length === 0) return null;

  return question.multianswer.marks.map((mark) => mark.answer_id);
};

const extractTruefalse = (question) => {
  if (!question.truefalse.mark) return null;

  return question.truefalse.mark.answer;
};

const extractEssay = (question) => {
  if (!question.essay.mark) return null;

  const decode = new Html5Entities().decode;
  return decode(question.essay.mark.content);
};

const extractShortanswer = (question) => {
  if (!question.mark) return null;

  return question.mark.content;
};

const extractMatching = (question) => {
  if (question.marks.length === 0) return null;

  return question.marks.map((mark) => ({
    statement_id: mark.statement_id,
    answer_id: mark.answer_id,
  }));
};

const extractStudentAnswer = (question) => {
  switch (question.type) {
    case "multichoice":
      return extractMultichoice(question);
    case "multianswer":
      return extractMultianswer(question);
    case "truefalse":
      return extractTruefalse(question);
    case "essay":
      return extractEssay(question);
    case "shortanswer":
      return extractShortanswer(question);
    case "matching":
      return extractMatching(question);
    default:
      return null;
  }
};

const extractStudentAnswers = (sections) => {
  return flatten(sections.map((section) => section.questions)).reduce(
    (accumulator, question) => {
      return {
        ...accumulator,
        [question.id]: {
          question_id: question.id,
          question_answer: extractStudentAnswer(question),
        },
      };
    },
    {}
  );
};

export default extractStudentAnswers;
