import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SimpleModal from "components/Modal/SimpleModal";
import FormHint from "components/Form/FormHint";
import isEmptyString from "validator/lib/isEmpty";
import { connect } from "react-redux";
import { startAccomplish } from "redux/accomplish/action";
import Alert from "components/Alert/Alert";
import SpinnerGrow from "components/Spinner/SpinnerGrow";

class StartExamConfirmationPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: props.isSecuredByToken ? "" : null,
      isLoading: false,
      tokenMismatch: false,
      isExamInactive: false,
      isServerBusy: false,
      isSomethingWentWrong: false,
    };
  }

  handleTokenChange = (e) => {
    this.setState({ token: e.target.value, tokenMismatch: false });
  };

  handleStart = (e) => {
    this.setState({
      isLoading: true,
      isSomethingWentWrong: false,
      isExamInactive: false,
      tokenMismatch: false,
      isServerBusy: false,
    });
    setTimeout(() => {
      this.props
        .startAccomplish(this.props.examId, this.state.token)
        .then((accomplish) =>
          this.props.history.push(
            `/exam/${this.props.examId}/accomplish/${accomplish.id}`
          )
        )
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              if (err.response.data.error[0].message === "Invalid exam") {
                this.setState({ isExamInactive: true, isLoading: false });
              }
              if (err.response.data.error[0].message === "Invalid exam token") {
                this.setState({ tokenMismatch: true, isLoading: false });
              }
            } else if (err.response.status === 403) {
              this.setState({ isExamInactive: true, isLoading: false });
            } else if (err.response.status === 429) {
              this.setState({ isServerBusy: true, isLoading: false });
            } else {
              this.setState({ isSomethingWentWrong: true, isLoading: false });
            }
          }
        });
    }, 500);
  };

  render() {
    const {
      duration,
      totalRemainingAttempt,
      isSecuredByToken,
      totalQuestion,
    } = this.props;

    return (
      <SimpleModal title="Memulai Ujian" onClose={this.props.onClose}>
        <form
          action="#"
          className="form-validate is-alter"
          noValidate="noValidate"
        >
          <div className="row no-gutters">
            <div className="col-6">
              <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  Waktu Pengerjaan
                </label>
                <div className="form-control-wrap">{duration} menit</div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-label" htmlFor="phone-no">
                  Sisa Kesempatan Mengulang
                </label>
                <div className="form-control-wrap">{totalRemainingAttempt}</div>
              </div>
            </div>
          </div>

          <div className="mt-5"></div>

          {totalRemainingAttempt === 0 && (
            <Alert warning>
              <strong>Kesempatan mengulang sudah habis.</strong>
            </Alert>
          )}

          {totalRemainingAttempt > 0 && (
            <>
              {isSecuredByToken && (
                <div className="form-group">
                  <FormHint.Parent className="mb-1">
                    Mintalah token kepada pihak sekolah.
                    <span className="text-primary">
                      <em className="icon ni ni-info"></em>
                    </span>
                  </FormHint.Parent>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Masukan token"
                      onChange={this.handleTokenChange}
                    />
                  </div>
                </div>
              )}

              {this.state.tokenMismatch && (
                <Alert warning>
                  <strong>Token salah</strong>.
                </Alert>
              )}

              {this.state.isExamInactive && (
                <Alert warning>
                  <strong>Ujian mungkin dinonaktifkan oleh sekolah.</strong>
                  <br />
                  Cobalah untuk reload halaman.
                </Alert>
              )}

              {this.state.isServerBusy && (
                <Alert warning>
                  <strong>Coba 10 detik lagi karena server sedang sibuk.</strong>
                </Alert>
              )}

              {this.state.isSomethingWentWrong && (
                <Alert warning>
                  <strong>Terjadi kesalahan.</strong>
                </Alert>
              )}

              {this.state.isLoading ? (
                <div className="text-center">
                  <SpinnerGrow variant="warning" />
                </div>
              ) : (
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                    onClick={this.handleStart}
                    disabled={
                      totalQuestion === 0 ||
                      (isSecuredByToken && isEmptyString(this.state.token))
                    }
                  >
                    Mulai Ujian
                  </button>
                </div>
              )}
            </>
          )}
        </form>
      </SimpleModal>
    );
  }
}

StartExamConfirmationPopup.propTypes = {};

export default connect(null, { startAccomplish })(
  withRouter(StartExamConfirmationPopup)
);
