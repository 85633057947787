import React from "react";
import PropTypes from "prop-types";
import Block from "components/atoms/Block/Block";

export default function PageHeader({
  heading,
  subheading = null,
  message,
  renderRight,
}) {
  return (
    <Block.Head size="lg">
      {subheading && (
        <Block.Head.Sub>
          <span>{subheading}</span>
        </Block.Head.Sub>
      )}
      <Block.Between size="md" className="g-4">
        <Block.Head.Content>
          <h2 className="nk-block-title page-title">{heading}</h2>
          <div className="text-soft">
            <p>{message}</p>
          </div>
        </Block.Head.Content>
        <Block.Head.Content>{renderRight && renderRight()}</Block.Head.Content>
      </Block.Between>
    </Block.Head>
  );
}

PageHeader.propTypes = {
  /**
   * Heading
   */
  heading: PropTypes.string.isRequired,
  /**
   * Subheading
   */
  subheading: PropTypes.string,
  /**
   * Message
   */
  message: PropTypes.string.isRequired,
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Render on right side
   */
  renderRight: PropTypes.func,
};
