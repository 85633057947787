import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

export default [
  rest.get(`${BASE_URL}/v1/my/transaction`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: EXAM_LIST,
      })
    );
  }),

  rest.get(`${BASE_URL}/v1/my/transaction/:id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: {
          id: "1",
          code: "EDU197584935849",
          type: "exam",
          formated_type: "Ujian",
          total_prices: 25000,
          status: "PROCESS",
          formated_status: "Menunggu Pembayaran",
          payment_method: {
            id: "1",
            name: "BCA KlikPay",
            tutorial:
              "Panduan pembayaran e-Wallet \r\n1. Pastikan anda mempunyai uang sebelum membayar",
          },
          exam_packages: [
            {
              id: "1",
              name: "Ujian Tryout #1",
            },
          ],
          payment: {
            type: "url",
            reference: "65748930574893",
            url:
              "http://sandbox.duitku.com/topup/topupdirectv2.aspx?ref=FTE1TR17PGKFAPUG3",
          },
          created_at: "2017-08-05T03:34:59.000Z",
          expired_at: "2017-08-06T03:34:59.000Z",
        },
      })
    );
  }),
];

const EXAM_LIST = [
  {
    id: "1",
    code: "EDU197584935849",
    type: "exam",
    formated_type: "Ujian",
    total_prices: 25000,
    status: "SUCCESS",
    formated_status: "Sukses",
    payment_method: {
      id: "1",
      name: "BCA KlikPay",
      tutorial:
        "Panduan pembayaran e-Wallet \r\n1. Pastikan anda mempunyai uang sebelum membayar",
    },
    exam_packages: [
      {
        id: "1",
        name: "Ujian Tryout #1",
      },
      {
        id: "1",
        name: "Ujian Tryout #2",
      },
    ],
    payment: {
      type: "url",
      reference: "65748930574893",
      url:
        "http://sandbox.duitku.com/topup/topupdirectv2.aspx?ref=FTE1TR17PGKFAPUG3",
    },
    created_at: "2017-08-05T03:34:59.000Z",
    expired_at: "2017-08-06T03:34:59.000Z",
  },
  {
    id: "1",
    code: "EDU197584935849",
    type: "exam",
    formated_type: "Ujian",
    total_prices: 25000,
    status: "SUCCESS",
    formated_status: "Sukses",
    payment_method: {
      id: "1",
      name: "BCA KlikPay",
      tutorial:
        "Panduan pembayaran e-Wallet \r\n1. Pastikan anda mempunyai uang sebelum membayar",
    },
    exam_packages: [
      {
        id: "1",
        name: "Ujian Tryout #1",
      },
    ],
    payment: {
      type: "url",
      reference: "65748930574893",
      url:
        "http://sandbox.duitku.com/topup/topupdirectv2.aspx?ref=FTE1TR17PGKFAPUG3",
    },
    created_at: "2017-08-05T03:34:59.000Z",
    expired_at: "2017-08-06T03:34:59.000Z",
  },
];
