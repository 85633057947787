import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const HOMEPAGE = {
  exam_packages: [...Array(10).keys()].map(() => ({
    thumbnail:
      "https://hatta.storage.dev.edulogy.id/file/school/exam/package/2021/01/15/wp3231265.jpg",
    id: 9,
    name: "Paket Ujian Cuan",
    description: "ini teh despripsi",
    tagline: "ini teh tagline",
    total_prices: 200000,
    start_at: "2021-01-14T17:00:00.000Z",
    finish_at: "2021-02-27T17:00:00.000Z",
    school: {
      logo: "https://static.storage.edulogy.id/edulogy/edulogy-logo.png",
      id: 45,
      name: "[DEV] EDU OFFICE",
    },
    categories: [
      {
        id: 1,
        name: "UTBK",
      },
      {
        id: 2,
        name: "SBMPTN",
      },
      {
        id: 3,
        name: "Bank Soal",
      },
      {
        id: 4,
        name: "CPNS",
      },
      {
        id: 5,
        name: "PAS",
      },
      {
        id: 6,
        name: "Saintek",
      },
      {
        id: 7,
        name: "Soshum",
      },
    ],
  })),
  schools: [...Array(20).keys()].map(() => ({
    id: 1,
    name: "EduSchool",
    logo:
      "https://hatta.storage.edulogy.id/file/school/logo/2020/04/30/asset-1-at-300x-5eaa5dae7c4c3.png",
  })),
};

export default [
  rest.get(`${BASE_URL}/v1/public/homepage`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: HOMEPAGE,
      })
    );
  }),
];
