import axios from "configuredAxios";

export default {
  getProfile: () => {
    return axios
      .get(`v1/user/profile`)
      .then((res) => res.data.data.student);
  },
  loginGoogle: ({ google_token }) => {
    return axios
      .post(`v1/auth/google-signin`, { google_token })
      .then((res) => res.data.data.auth);
  },
  updateProfile: ({ birth_place, birth_at, phone, photo_id }) => {
    return axios.post(`v1/user/profile`, {
      birth_place,
      birth_at,
      phone,
      photo_id,
    });
  },
  updatePassword: ({ old_password, new_password }) => {
    return axios.post(`v1/user/password`, {
      old_password,
      new_password,
    });
  },
  attachGoogle: ({ google_token }) => {
    return axios.post(
      `v1/auth/google-attach`,
      {
      google_token,
      },
      { __bypass: [400] }
    );
  },
  dettachGoogle: () => {
    return axios.post(`v1/auth/google-detach`);
  },
};
