import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid #dbdfea;
  margin-top: 0.75rem !important;
  padding: 10px 10px;
  border-radius: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
    border-color: #6576ff;
  }

  & > * {
    z-index: 1;
    position: relative;
  }
  & > .layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

// TODO: jadikan component yang tanpa harus cloneElement. Misal: <RadioBordered />
class AnswerItemBordered extends React.Component {
  constructor(props) {
    super(props);
    this.state = { labelRef: null };
  }

  triggerClickOnRadio = () => this.state.labelRef.current.click();

  render() {
    const childElement = React.Children.only(this.props.children);

    return (
      <Container>
        <div className="layer" onClick={this.triggerClickOnRadio}></div>

        {React.cloneElement(childElement, {
          labelRef: (ref) => this.setState({ labelRef: ref }),
        })}
      </Container>
    );
  }
}

export default AnswerItemBordered;
