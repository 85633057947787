import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const FILE = {
  data: {
    id: "93",
    type: "image",
    name: "dodol-garut.jpg",
    extension: "jpg",
    mimetype: "image/jpeg",
    size: 13174,
    url:
      "https://halim.storage.dev.edulogy.id/file/teacher/administration/question/2021/02/14/dodol-garut.jpg",
    created_at: "2021-02-14T02:31:00.000000Z",
    updated_at: "2021-02-14T02:31:00.000000Z",
  },
};

export default [
  rest.get(`${BASE_URL}/v1/file`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: FILE,
      })
    );
  }),
];
