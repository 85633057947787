import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resumeAccomplish,
  endAccomplish,
  exitAccomplish,
} from "redux/accomplish/action";
import Accomplish from "./Accomplish";
import Header from "./Header";
import PageLoading from "components/Loading/PageLoading";
import SharedEditor from "./SharedEditor";
import isEmptyObject from "lodash/isEmpty";
import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import values from "lodash/values";
import { Prompt } from "react-router-dom";
import Alert from "components/Alert/Alert";
import Toastr from "components/Notification/Toastr";
import { hideTawkChat, showTawkChat } from "utils/misc";

const EXIT_MESSAGE =
  "Apakah yakin keluar? Jawaban kamu bisa hilang bila belum tersimpan di server.";

/**
 * NOTE: laman ini digunakan juga untuk resume ujian
 */
class ExamAccomplishPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSubmit: false,
      isSubmitFailed: false,
      isServeryBusy: false,
    };
  }

  componentDidMount() {
    window.onbeforeunload = () => EXIT_MESSAGE;
    hideTawkChat();

    // ACCOMPLISH_STARTED
    if (!isEmptyObject(this.props.accomplish)) {
      this.setState({ isLoading: false });
      return;
    }
    // ACCOMPLISH_RESUMED
    const routeParams = this.props.match.params;
    this.props
      .resumeAccomplish(routeParams.exam, routeParams.accomplish)
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        if (err.accomplishAlreadySubmitted) {
          return this.props.history.push(
            `/exam/${err.accomplish.exam.id}/submission/${err.accomplish.id}/result`
          );
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isSubmitFailed && this.state.isSubmitFailed) {
      setTimeout(() => window.scrollTo(0, 0), 1000);
    }
  }

  componentWillUnmount() {
    showTawkChat();
    if (this.state.isSubmit) return;
    this.props.exitAccomplish();
  }

  submit = () => {
    this.setState({
      isSubmit: true,
      isLoading: true,
      isSubmitFailed: false,
      isServeryBusy: false,
    });

    const { accomplish, studentAnswers } = this.props;
    setTimeout(() => {
      this.props
        .endAccomplish(accomplish, values(studentAnswers))
        .then(() => {
          this.props.history.push(
            `/exam/${accomplish.exam.id}/submission/${accomplish.id}/result`
          );
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            this.props.history.push(
              `/exam/${accomplish.exam.id}/submission/${accomplish.id}/result`
            );
            return;
          }
          if (err.response && err.response.status === 429) {
            this.setState({ isServeryBusy: true });
          }

          this.setState({
            isLoading: false,
            isSubmit: false,
            isSubmitFailed: true,
          });
        });
    }, 500);
  };

  render() {
    if (this.state.isLoading) {
      return <PageLoading isLoading={true} />;
    }

    const { accomplish } = this.props;
    return (
      <>
        <Prompt
          when={!this.state.isSubmit}
          message={(location, action) => {
            // TODO: masih bermasalah kadang URL keubah meski "cancel"
            return !this.state.isSubmit ? EXIT_MESSAGE : true;
          }}
        />

        {this.state.isSubmitFailed && (
          <Alert warning pro>
            <div>
              <strong>Gagal menyimpan hasil ujian</strong>
              <br />
              {this.state.isServeryBusy ? (
                <span>Server sibuk mohon coba 10 detik lagi</span>
              ) : (
                <span>Pastikan koneksi internet kamu lancar.</span>
              )}
            </div>
          </Alert>
        )}

        <AccomplishContext.Provider value={{ submit: this.submit }}>
          <Header
            examTitle={accomplish.exam.title}
            examDescription={accomplish.exam.description}
          />

          <Toastr position="center" maxItem={1}>
            <SharedEditor accomplish={accomplish}>
              <Accomplish data={accomplish} />
            </SharedEditor>
          </Toastr>
        </AccomplishContext.Provider>
      </>
    );
  }
}

ExamAccomplishPage.propTypes = {};

const mapStateToProps = (state) => ({
  accomplish: state.accomplish.data,
  studentAnswers: state.accomplish.studentAnswers,
});

export default connect(mapStateToProps, {
  resumeAccomplish,
  endAccomplish,
  exitAccomplish,
})(ExamAccomplishPage);
