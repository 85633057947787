import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Page from "components/Page";

const GuestRoute = ({
  isAuthenticated,
  component: Component,
  layout: Layout,
  title,
  ...rest
}) => {
  if (isAuthenticated) return <Redirect to="/home" />;

  document.title = title;

  if (!Layout) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Page>
            <Component {...props} />
          </Page>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Page>
            <Component {...props} />
          </Page>
        </Layout>
      )}
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType,
  isAuthenticated: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.access_token,
  };
}

export default connect(mapStateToProps)(GuestRoute);
