import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert/Alert";
import Log from "domain/Log/Log";

export default ({ url, extension }) => {
  const sources = {
    mp3: <source src={url} type="audio/mpeg" />,
    mpga: <source src={url} type="audio/mpeg" />, // di guru ada kesalahan upload mp3 jadi mpga ekstensinya
    wav: <source src={url} type="audio/wav" />,
    ogg: <source src={url} type="audio/ogg" />,
  };

  return (
    <audio controls style={{ width: "100%" }}>
      {sources[extension] || (
        <Alert warning>
          Browser kamu tidak mendukung pemutar audio. Sebagai gantinya klik
          tombol berikut untuk mengunduh file audio: <br />
          <a href={url} target="_blank" className="btn btn-primary" download>
            <em className="ni ni-download"></em> Unduh
          </a>
        </Alert>
      )}
    </audio>
  );
};
