import React from "react";
import PropTypes from "prop-types";
import BadgePill from "components/Badge/BadgePill";
import StartExamConfirmationPopup from "./StartExamConfirmationPopup";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import NioIcon from "components/atoms/Icon/NioIcon";

class ExamCard extends React.Component {
  constructor(props) {
    super(props);

    this.showConfirmationPopup = this.showConfirmationPopup.bind(this);
    this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this);
    this.state = { isConfirmationPopupShow: false };
  }

  showConfirmationPopup = (e) => {
    this.setState({ isConfirmationPopupShow: true });
  };

  closeConfirmationPopup = (e) => {
    e.preventDefault();
    this.setState({ isConfirmationPopupShow: false });
  };

  render() {
    const {
      examId,
      title,
      type,
      startAt,
      finishAt,
      duration,
      totalAttempt,
      totalRemainingAttempt,
      isSecuredByToken,
      totalQuestion,
      lessonName,
      status_accomplish,
      examPackage,
      school,
    } = this.props;

    return (
      <>
        {this.state.isConfirmationPopupShow && (
          <StartExamConfirmationPopup
            examId={examId}
            duration={duration}
            totalQuestion={totalQuestion}
            totalRemainingAttempt={totalRemainingAttempt}
            totalAttempt={totalAttempt}
            isSecuredByToken={isSecuredByToken}
            onStart={this.closeConfirmationPopup}
            onClose={this.closeConfirmationPopup}
          />
        )}

        <div className="card card-bordered sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-xl-12 col-sm-8">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        {examPackage && (
                          <BadgePill
                            variant="primary"
                            style={{ marginLeft: 0, marginRight: ".5rem" }}
                          >
                            Paket
                          </BadgePill>
                        )}
                        <BadgePill
                          variant="light"
                          style={{ marginLeft: 0, marginRight: ".5rem" }}
                        >
                          {type}
                        </BadgePill>
                        <a
                          href="#"
                          onClick={
                            (e) =>
                              e.preventDefault() /* TODO: laman detail ujian blm terseida */
                          }
                        >
                          {title}
                        </a>
                      </h6>
                      <p>
                        <small>
                          Mata Pelajaran:{" "}
                          <span className="text-base">{lessonName}</span>
                        </small>
                        {examPackage && (
                          <>
                            <br />
                            <small>
                              Paket:{" "}
                              <span className="text-base">
                                {examPackage.name}
                              </span>{" "}
                              &middot; Penyelenggara:{" "}
                              <span className="text-base">{school.name}</span>
                            </small>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner">
                <ul className="row gx-1">
                  <li className="col-4 col-lg-3">
                    <p>
                      <span className="text-soft">Durasi</span>
                      {duration} Menit
                    </p>
                  </li>
                  <li className="col-4 col-lg-3">
                    <p>
                      <span className="text-soft">Soal</span> {totalQuestion}
                    </p>
                  </li>
                  <li className="col-4 col-lg-3">
                    <p>
                      <span className="text-soft">Sisa Kesempatan</span>{" "}
                      {totalRemainingAttempt}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sp-plan-action card-inner">
                <div className="sp-plan-btn">
                  {totalRemainingAttempt < totalAttempt ? (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-dim btn-white btn-dim btn-outline-primary"
                        data-toggle="modal"
                        data-target="#subscription-change"
                        onClick={this.showConfirmationPopup}
                        disabled={status_accomplish === "inactive"}
                      >
                        <span>Mulai Baru</span>
                      </button>
                      <Link
                        to={{ pathname: `exam/${examId}/submission` }}
                        className="btn btn-dim btn-white btn-outline-primary"
                      >
                        <span>Riwayat</span>
                      </Link>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#subscription-change"
                      onClick={this.showConfirmationPopup}
                      disabled={status_accomplish === "inactive"}
                    >
                      <span>Mulai</span>
                    </button>
                  )}
                </div>
                <div className="sp-plan-note text-md-center">
                  {moment().isBefore(startAt) ? (
                    <p>
                      Dimulai{" "}
                      <span>{moment(startAt).format("D MMMM HH:mm z")}</span>
                    </p>
                  ) : (
                    <p>
                      Berakhir{" "}
                      <span>{moment(finishAt).format("D MMMM HH:mm z")}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ExamCard.propTypes = {};

export default ExamCard;
