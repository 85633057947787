import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";

class Archieve extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <a href={data.archieve.url} download>
        <Button
          useModal={false}
          text={data.archieve.name || data.archieve.url}
          icon="file-zip"
        />
      </a>
    );
  }
}

Archieve.propTypes = {};

export default Archieve;
