// export const PACKAGES = [];

export const PACKAGES = [
  {
    id: 1,
    name: "Paket CPNS 2020",
    description: null,
    total_prices: 50000,
    start_at: "2017-08-06T03:34:59.000Z",
    finish_at: "2017-08-06T05:34:59.000Z",
    categories: [
      {
        id: "1",
        name: "CPNS",
      },
      {
        id: "2",
        name: "UTBK",
      },
    ],
    school: {
      id: "45",
      name: "Dinas Kota Bandung",
      logo:
        "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
      type: "institution",
      formated_type: "Lembaga",
    },
  },
  {
    id: 2,
    name: "Pelatihan Bahasa Inggris",
    description: "Paket yang ditujukan untuk kalangan lulusan minimal SMA.",
    total_prices: null,
    start_at: "2017-08-06T03:34:59.000Z",
    finish_at: "2017-08-06T05:34:59.000Z",
    categories: [
      {
        id: "1",
        name: "Bahasa Asing",
      },
    ],
    school: {
      id: "45",
      name: "Focus Excelent",
      logo:
        "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
      type: "course",
      formated_type: "Bimbingan Belajar",
    },
  },
];

export const PACKAGE_DETAILS = [
  {
    id: 1,
    name: "Paket CPNS 2020",
    description: null,
    total_prices: 50000,
    start_at: "2017-08-06T03:34:59.000Z",
    finish_at: "2017-08-06T05:34:59.000Z",
    categories: [
      {
        id: "1",
        name: "CPNS",
      },
      {
        id: "2",
        name: "UTBK",
      },
    ],
    exams: [
      {
        id: "1",
        title: "Ujian CPNS untuk Hukum Tata Negara",
        total_max_participants: 500,
        total_current_participants: 324,
        total_questions: 40,
        total_attempts: 3,
        type: "schoolexam",
        formated_type: "Ujian Sekolah",
        school_lesson: {
          id: "232",
          name: "Umum",
        },
        start_at: "2017-08-06T03:34:59.000Z",
        finish_at: "2017-08-06T03:34:59.000Z",
      },
      {
        id: "2",
        title: "UTBK SMA untuk Siswa Calon CPNS",
        total_max_participants: 100,
        total_current_participants: 50,
        total_questions: 40,
        total_attempts: 1,
        type: "tryout",
        formated_type: "Pemantapan",
        school_lesson: {
          id: "232",
          name: "Umum",
        },
        start_at: "2017-08-06T03:34:59.000Z",
        finish_at: "2017-08-06T03:34:59.000Z",
      },
    ],
    school: {
      id: "45",
      name: "Dinas Kota Bandung",
      logo:
        "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
      type: "institution",
      formated_type: "Lembaga",
    },
  },
  {
    id: 2,
    name: "Pelatihan Bahasa Inggris",
    description: "Paket yang ditujukan untuk kalangan lulusan minimal SMA.",
    total_prices: null,
    start_at: "2017-08-06T03:34:59.000Z",
    finish_at: "2017-08-06T05:34:59.000Z",
    categories: [
      {
        id: "1",
        name: "Bahasa Asing",
      },
    ],
    exams: [
      {
        id: "1",
        title: "Grammar Structure #1",
        total_max_participants: 1000,
        total_current_participants: 999,
        total_questions: 10,
        total_attempts: 3,
        type: "schoolexam",
        formated_type: "Ujian Sekolah",
        school_lesson: {
          id: "232",
          name: "Bahasa Inggris",
        },
        start_at: "2017-08-06T03:34:59.000Z",
        finish_at: "2017-08-06T03:34:59.000Z",
      },
    ],
    school: {
      id: "45",
      name: "Focus Excelent",
      logo:
        "https://hatta.storage.edulogy.id/file/teacher/photo/2020/07/16/sample-640426.JPEG",
      type: "course",
      formated_type: "Bimbingan Belajar",
    },
  },
];
