import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";
import VideoViewer from "components/Viewer/Video";

export default class Video extends React.Component {
  static propTypes = {};
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button text={data.video.name || data.video.url} icon="video">
        <Button.Modal>
          <VideoViewer url={data.video.url} extension={data.video.extension} />
        </Button.Modal>
      </Button>
    );
  }
}
