import { LESSON_FETCHED, EXAM_FETCHED } from "./type";
import api from "api";

export const lessonFetched = (lessons) => ({
  type: LESSON_FETCHED,
  lessons,
});

export const examFetched = (exams) => ({
  type: EXAM_FETCHED,
  exams,
});

export const fetchLessons = () => {
  return (dispatch) =>
    api.exam.getLessons().then((lessons) => dispatch(lessonFetched(lessons)));
};

export const fetchExams = (params) => {
  return (dispatch) =>
    api.exam.getExams(params).then((exams) => dispatch(examFetched(exams)));
};
