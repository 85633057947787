import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import styled from "styled-components";
import { hideErrorPopup } from "redux/page/action";
import { connect } from "react-redux";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d9e1ef;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

class PageError extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    return (
      <ModalPortal>
        <Container>
          <div className="nk-content ">
            <div className="nk-block nk-block-middle wide-xs mx-auto">
              <div className="nk-block-content nk-error-ld text-center">
                <h1 className="nk-error-head">
                  <em className="ni ni-puzzle"></em>
                </h1>
                <h3 className="nk-error-title">Terjadi Kesalahan</h3>
                <p className="nk-error-text">
                  Laporan dikirimkan kepada tim teknisi dan akan segera
                  direspon. Terima Kasih.
                </p>
                <button
                  type="button"
                  // onClick={() => this.props.hideErrorPopup()}
                  onClick={() => window.location.reload()}
                  className="btn btn-lg btn-primary mt-2"
                >
                  Muat Ulang
                </button>
              </div>
            </div>
          </div>
        </Container>
      </ModalPortal>
    );
  }
}

PageError.propTypes = {};

export default connect(null, { hideErrorPopup })(PageError);
