import { SUBMISSION_FETCHED, SUBMISSION_EXAM_DETAIL_FETCHED } from "./type";

const initialState = {
  exam: {},
  data: null,
}

export function submission(state = initialState, action = {}) {
  switch (action.type) {
    case SUBMISSION_EXAM_DETAIL_FETCHED:
      return { ...state, exam: action.exam };
    case SUBMISSION_FETCHED: // TODO: rename SUBMISSION -> SUBMISSIONS
      return { ...state, data: action.data };
    default:
      return state;
  }
}
