import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function BaseBadge({
  variant,
  children,
  size = null,
  className = null,
  dim = false,
  ...restProps
}) {
  return (
    <span
      className={cx(
        "badge",
        variant && " badge-" + variant,
        size && "badge-" + size,
        dim && "badge-dim",
        className
      )}
      {...restProps}
    >
      {children}
    </span>
  );
}

BaseBadge.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm", null]), // karena tempate dashlite seperti ini
  variant: PropTypes.oneOf([
    "light",
    "lighter",
    "gray",
    "dark",
    "danger",
    "primary",
    "success",
    "warning",
    "info",
    "outline-primary",
    "outline-secondary",
    "outline-success",
    "outline-info",
    "outline-warning",
    "outline-danger",
    "outline-dark",
  ]).isRequired,
};
