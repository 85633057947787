import axios from "configuredAxios";
import qs from "qs";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

// TODO: rename get() -> getAll()
// TODO: buat api per file, contoh: api/User.js, api/Accomplish.js, ...dsb

const api = {
  user: {
    login: (credentials) =>
      axios
        .post("v1/user/auth/login", { ...credentials }, { __bypass: [400] }) // 400 can "username-password-invalid" OR service error
        .then((res) => res.data.data.auth),
    logout: (access_token) =>
      axios.post("/v1/user/auth/logout", { access_token }),
    signup: (user) =>
      axios.post("/api/users", { user }).then((res) => res.data.user),
    confirm: (token) =>
      axios
        .post("/api/auth/confirmation", { token })
        .then((res) => res.data.user),
    resetPasswordRequest: (email) =>
      axios.post("/v1/user/auth/reset", { email }),
    resetPasswordVerify: (data) =>
      axios.post("/v1/user/auth/verify", { ...data }),
  },
  exam: {
    getLessons: () =>
      axios.get("v1/school/exam/filter/lesson").then((res) => res.data.data),
    getExams: (params = {}) =>
      axios
        .get("/v1/school/exam", { params })
        .then((res) => res.data.data),
    getExam: (examId) =>
      axios.get(`/v1/school/exam/${examId}`).then((res) => res.data.data),
  },
  accomplish: {
    resume: (examId, accomplishId) => {
      return axios
        .get(`/v1/school/exam/${examId}/submission/${accomplishId}`)
        .then((res) => res.data.data);
    },
    start: (examId, token) => {
      const payload = token !== null ? { token } : {};
      return axios
        .post(`/v1/school/exam/${examId}/accomplish`, payload, {
          __bypass: [403, 429],
        })
        .then((res) => res.data.data);
    },
    saveAnswer: ({ examId, accomplishId, answer }) => {
      return axios.post(
        `/v1/school/exam/${examId}/accomplish/${accomplishId}/update`,
        { ...answer },
        { timeout: 4000, __bypass: [403, 429] }
      );
    },
    submit: (examId, accomplishId, answers) => {
      return axios.post(
        `/v1/school/exam/${examId}/accomplish/${accomplishId}/submit`,
        { answers },
        { __bypass: [403, 429] }
      );
    },
  },
  submission: {
    get: (examId, params) =>
      axios
        .get(`/v1/school/exam/${examId}/submission`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((res) => res.data.data),
    getDetail: (examId, submissionId) =>
      axios
        .get(`/v1/school/exam/${examId}/submission/${submissionId}`)
        .then((res) => res.data.data),
    getResult: (submissionId) =>
      axios
        .get(`/v1/exam/submission/${submissionId}/result`)
        .then((res) => res.data.data),
  },
};

export default api;
