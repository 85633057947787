import _ from "lodash";

const GenerateQuestionList = (examSections) => {
  // menambahkan property
  let $currentQuestionAbsoluteIndex = -1;
  for (const [sectionIndex, section] of examSections.entries()) {
    for (const [questionIndex, question] of section.questions.entries()) {
      $currentQuestionAbsoluteIndex++;
      question["absoluteIndex"] = $currentQuestionAbsoluteIndex;

      question["relativeIndex"] = questionIndex;
      question["section"] = { ...section, index: sectionIndex };
    }
  }

  const questionList = _.flatten(
    examSections.map((section) => {
      return section.questions;
    })
  )

  // menambahkan method
  for (const question of questionList) {
     question["next"] = () => questionList[question.absoluteIndex + 1];
     question["prev"] = () => questionList[question.absoluteIndex + -1];
  }

  return questionList;
};

export default GenerateQuestionList;
