import React from "react";
import PropTypes from "prop-types";
import { InlineList, InlineListItem } from "components/InlineList/InlineList";
import styled from "styled-components";
import Icon from "components/Icon/Icon";
import cx from "classnames";
import { connect } from "react-redux";
import values from "lodash/values";

const QuestionNumberContainer = styled.div`
  border-radius: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &:hover {
    border: 1px solid #798bff;
  }

  &:active {
    border-color: white;
  }
`;
const QuestionNumber = styled.div`
  width: 25px;
  height: 25px;
  font-size: 10px;
  /* centering text */
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
`;
const CheckIconContainer = styled.span`
  border: none;
  font-size: 12px;
  position: absolute;
  bottom: -4px;
  right: -1px;
`;
class QuestionIndex extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children: label, isCheck, isActive } = this.props;

    return (
      <QuestionNumberContainer
        className={cx("user-avatar", {
          "bg-light": !isActive,
          "text-gray": !isActive,
          "bg-primary": isActive,
          "text-white": isActive,
        })}
        onClick={this.props.onClick}
      >
        <QuestionNumber>{label}</QuestionNumber>
        {isCheck && (
          <CheckIconContainer className="text-teal">
            <Icon nio="check-fill-c" />
          </CheckIconContainer>
        )}
      </QuestionNumberContainer>
    );
  }
}

class SectionQuestionList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sectionQuestionList,
      currentQuestion,
      answeredQuestionIds,
    } = this.props;

    return (
      <div className="card-inner">
        <InlineList>
          {sectionQuestionList.map((question, index) => (
            <InlineListItem key={question.absoluteIndex}>
              <QuestionIndex
                isCheck={answeredQuestionIds.includes(question.id)}
                isActive={
                  currentQuestion.absoluteIndex === question.absoluteIndex
                }
                onClick={() => this.props.onClick(question.absoluteIndex)}
              >
                {question.absoluteIndex + 1}
              </QuestionIndex>
            </InlineListItem>
          ))}
        </InlineList>
      </div>
    );
  }
}

SectionQuestionList.propTypes = {};

const mapStateToProps = (state) => ({
  currentQuestion: state.accomplish.currentQuestion || {},
  answeredQuestionIds: values(state.accomplish.studentAnswers)
    .filter((studentAnswer) => studentAnswer.question_answer !== null)
    .map((studentAnswer) => studentAnswer.question_id),
});

export default connect(mapStateToProps)(SectionQuestionList);
