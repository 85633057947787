import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "../variables";

export default [
  rest.get(`${BASE_URL}/v1/master/exam-category`, (req, res, ctx) => {
    return res(
      ctx.delay(200),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: [
          {
            id: 1,
            name: "CPNS",
          },
          {
            id: 2,
            name: "UTBK",
          },
        ],
      })
    );
  }),
];
