import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "moment-duration-format";
import { connect } from "react-redux";
import values from "lodash/values";
import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import ErrorBoundary from "components/Error/ErrorBoundary";

class index extends React.Component {
  static contextType = AccomplishContext;

  constructor(props) {
    super(props);

    this.shouldFinishAt = moment().add(
      Math.ceil(props.remainingDurationInSecond / 60),
      "minutes"
    );

    this.state = {
      remainingDuration: this.shouldFinishAt.diff(moment(), "seconds"),
    };
  }

  componentDidMount() {
    const timer = setInterval(() => {
      if (this.state.remainingDuration <= 0) {
        clearInterval(timer);
        this.context.submit();
        return;
      }

      this.setState({
        remainingDuration: this.shouldFinishAt.diff(moment(), "seconds"),
      });
    }, 1000);
  }

  componendDidUpdate() {
    document.title =
      "Siswa waktu: " +
      moment
        .duration(this.state.remainingDuration, "seconds")
        .format("H:mm:ss", { trim: false });
  }

  render() {
    const {
      examInstruction,
      totalQuestion,
      totalAnsweredQuestion,
    } = this.props;

    return (
      <div className="card-inner-group" style={{ borderRight: "none" }}>
        <div className="card-inner">
          <span className="sub-text sub-text-sm text-dark">Waktu tersisa:</span>
          <span className="sub-text sub-text-sm text-soft">
            {moment
              .duration(this.state.remainingDuration, "seconds")
              .format("H:mm:ss", { trim: false })}
          </span>
          <br />
          <span className="sub-text sub-text-sm text-dark">
            Kamu sudah menjawab:
          </span>
          <span className="sub-text sub-text-sm text-soft">
            {totalAnsweredQuestion} dari {totalQuestion}
          </span>
        </div>
        <div className="card-inner">
          <h6>Instruksi</h6>
          <span className="sub-text sub-text-sm text-soft">
            {examInstruction ? examInstruction : <em>Tidak ada instruksi.</em>}
          </span>
        </div>
      </div>
    );
  }
}

index.propTypes = {};

const mapStateToProps = (state) => ({
  totalAnsweredQuestion: values(state.accomplish.studentAnswers).filter(
    (studentAnswer) => studentAnswer.question_answer !== null
  ).length,
});

const LeftSidebarRedux = connect(mapStateToProps)(index);

export default (props) => (
  <ErrorBoundary fallback={<span>Oops, terjadi kesalahan.</span>}>
    <LeftSidebarRedux {...props} />
  </ErrorBoundary>
);
