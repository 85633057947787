import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExamSectionHeader from "./ExamSectionHeader";
import ExamSectionQuestionList from "./ExamSectionQuestionList";
import { connect } from "react-redux";
import isEmptyObject from "lodash/isEmpty";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  & {
    border-left: 1px solid #dbdfea;
    border-right: none !important;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    & {
      border-left: none;
      border-top: 1px solid #dbdfea;
    }
  }
`;

class RightSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSectionIndex: 0,
      componentStateUpdatedAt: null,
    };
  }

  expand = (index) => {
    this.setState({
      currentSectionIndex: index,
      componentStateUpdatedAt: new Date().getTime(),
    });
  };

  render() {
    const { questionList, examSections } = this.props;

    // TODO: apakah ini cara yang benar?
    const currentSectionIndex =
      this.props.ReduxStateUpdatedAt > this.state.componentStateUpdatedAt
        ? this.props.currentSectionIndex
        : this.state.currentSectionIndex;

    return (
      <Container className="card-inner-group">
        {/* <div className="card-inner text-center bg-purple-dim">
          <h6 className="text-soft">BAGIAN</h6>
        </div> */}

        {examSections.map((section, index) => (
          <React.Fragment key={index}>
            <ExamSectionHeader
              section={section}
              onClick={() => this.expand(index)}
            />
            {currentSectionIndex === index && (
              <ExamSectionQuestionList
                onClick={(questionAbsoluteIndex) =>
                  this.props.onQuestionClicked(questionAbsoluteIndex)
                }
                sectionQuestionList={questionList.filter(
                  (question) => question.section.index === index
                )}
              />
            )}
          </React.Fragment>
        ))}
      </Container>
    );
  }
}

RightSidebar.propTypes = {};

const mapStateToProps = (state) => ({
  ReduxStateUpdatedAt: new Date().getTime(),
  currentSectionIndex: !isEmptyObject(state.accomplish.currentQuestion)
    ? state.accomplish.currentQuestion.section.index
    : {},
});

const RightSidebarRedux = connect(mapStateToProps)(RightSidebar);

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <RightSidebarRedux {...props} />
  </ErrorBoundary>
)
