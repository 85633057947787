import masterExamCategoryAPIs from "./mockAPI/master/examCategory";
import masterSchoolTypeAPIs from "./mockAPI/master/schoolType";
import examPackageAPIs from "./mockAPI/school/examPackage";
import schoolAPIs from "./mockAPI/school/school";
import myAPIs from "./mockAPI/my";
import fileAPIs from "./mockAPI/file";
import publicAPIs from "./mockAPI/public";
import transactionAPIs from "./mockAPI/transaction";

export const handlers = [
  ...masterExamCategoryAPIs,
  ...masterSchoolTypeAPIs,
  ...examPackageAPIs,
  ...schoolAPIs,
  ...myAPIs,
  ...fileAPIs,
  ...publicAPIs,
  ...transactionAPIs,
];
