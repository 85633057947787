import React from "react";
import PropTypes from "prop-types";
import ModalPortal from "components/ModalPortal";
import cx from "classnames";

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timer: this.props.timer || 0 };
  }

  componentDidMount() {
    document.body.classList.add("modal-open");

    const timer = setInterval(() => {
      if (this.state.timer <= 0) {
        clearInterval(timer);
        return;
      }

      this.setState((state) => ({
        timer: state.timer - 1,
      }));
    }, 300);
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { timer } = this.state;
    const {
      message,
      acceptLabel,
      cancelLabel = "Batal",
      onAccept,
      onCancel,
      variant = "primary",
    } = this.props;
    return (
      <ModalPortal>
        <div className="modal-backdrop fade show"></div>
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ paddingRight: "12px", display: "block" }}
          aria-modal="true"
        >
          <div className={"modal-dialog modal-dialog modal-md"} role="document">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h5 className="modal-title"></h5>
                <button
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.onClose}
                >
                  <em className="icon ni ni-cross"></em>
                </button>
              </div> */}
              <div className="modal-body">
                <div className="row gy-2">
                  <div className="col-sm-12 mb-3">{message}</div>
                  <div className="col-sm-6">
                    <button
                      className="btn btn-light btn-block"
                      onClick={onCancel}
                    >
                      {cancelLabel}
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button
                      onClick={onAccept}
                      disabled={timer !== 0}
                      className={cx("btn", "btn-block", "btn-" + variant)}
                    >
                      {acceptLabel} {timer > 0 && <>({timer})</>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  }
}

ConfirmModal.propTypes = {};

export default ConfirmModal;
