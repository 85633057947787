import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "../variables";
import { PACKAGES, PACKAGE_DETAILS } from "./examPackage.data";

export default [
  rest.get(`${BASE_URL}/v1/exam-package`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: PACKAGES,
      })
    );
  }),

  rest.get(`${BASE_URL}/v1/exam-package/:id`, (req, res, ctx) => {
    const _package = PACKAGE_DETAILS.find(({ id }) => +id === +req.params.id);
    if (!_package) {
      return res(ctx.status(404), ctx.json({}));
    }
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: _package,
      })
    );
  }),

  rest.get(`${BASE_URL}/v1/payment/exam`, (req, res, ctx) => {
    const exam_package_ids = +req.params.exam_package_ids;
    const payment_method_id = +req.params.payment_method_id;

    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: {},
      })
    );
  }),
];
