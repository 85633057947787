import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";

export default class Url extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <a href={data.url.url} target="_blank">
        <Button useModal={false} text={data.url.url} icon="link-alt" />
      </a>
    );
  }
}
