import flatten from "lodash/flatten";

/**
 * return initial jawaban siswa seperti ini:
 *
 * {
 *  [QUESTION_ID]: {
 *    question_id: [QUESTION_ID],
 *    question_answer: null
 *  },
 *  [QUESTION_ID]: {
 *    question_id: [QUESTION_ID],
 *    question_answer: null
 *  }
 * }
 *
 * Kenapa saya menggunakan bentuk object of object, tidak array of object?
 * Agar lebih mudah ketika ingin mengambil jawaban untuk QUESTION_ID tertentu.
 * Tanpa harus mencari.
 *
 * Contoh:
 *    studentAnswers[<QUESTION_ID>]    -> return object jawaban untuk QUESTION_ID
 * ketimbang ketika pakai array, cukup panjang dan ribet:
 *    studentAnswers.find(
 *      (answer) => answer.question_id === <QUESTION_ID>
 *    )
 */
export default function(sections) {
  return flatten(sections.map((section) => section.questions)).reduce(
    (accumulator, question) => {
      return {
        ...accumulator,
        [question.id]: {
          question_id: question.id,
          question_answer: null,
        },
      };
    },
    {}
  );
}
