import React from "react";
import PropTypes from "prop-types";
import SimplePageHeader from "components/PageHeader/SimplePageHeader";
import RankSVG from "./rank.svg";
import { connect } from "react-redux";
import moment from "moment-timezone";
import "moment-duration-format";
import api from "api";
import Alert from "components/atoms/Alert/Alert";
import prettyFloat from "domain/Float/PrettyFloat";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import env from "domain/Environment/Env";
import { Link, withRouter } from "react-router-dom";
import NioIcon from "components/atoms/Icon/NioIcon";

class index extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null, isLoading: true };
  }

  componentDidMount() {
    setTimeout(() => {
      const routeParams = this.props.match.params;
      api.submission
        .getResult(routeParams.submission)
        .then((data) => this.setState({ data, isLoading: false }));
    }, 500);
  }

  render() {
    const { data } = this.state;

    if (data && data.status !== "finished") {
      return <Alert warning>Ujian belum selesai.</Alert>;
    }

    return (
      <>
        <div className="mb-2">
          <Link
            to={`/exam/${this.props.match.params.exam}/submission`}
            className="text-soft fs-18px"
          >
            <NioIcon name="arrow-left" /> <span>Kembali</span>
          </Link>
        </div>

        <SimplePageHeader
          heading="Hasil Ujian"
          message={
            "Semangat dan pantang menyerah " +
            this.props.studentFirstName +
            " apapun hasil ujianmu."
          }
        />

        {this.state.isLoading ? (
          <div className="text-center" style={{ marginTop: "80px" }}>
            <SpinnerGrow variant="warning" size="3rem" />
          </div>
        ) : (
          <div className="nk-block">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <div className="card card-bordered">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="sp-plan-head">
                        <h6 className="title mb-0">{data.exam.title}</h6>
                        <p className="text-soft fs-12px">
                          {data.exam.description || (
                            <em>Tidak ada deskripsi.</em>
                          )}
                        </p>
                      </div>
                      <div className="sp-plan-desc sp-plan-desc-mb">
                        <ul className="row gx-1 mt-3">
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">Total Soal</span>
                              <strong className="">
                                {data.total_questions}
                              </strong>
                            </p>
                          </li>
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">Total Terjawab</span>
                              <strong className="">{data.total_filleds}</strong>
                            </p>
                          </li>
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">
                                Persentase Terjawab
                              </span>
                              <div>
                                <span className="badge badge-warning">
                                  {prettyFloat(
                                    (data.total_filleds /
                                      data.total_questions) *
                                      100
                                  )}
                                  %
                                </span>
                              </div>
                            </p>
                          </li>
                        </ul>
                        <ul className="row gx-1">
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">Waktu Mulai</span>
                              <strong className="">
                                {moment(data.started_at).format("HH:mm z")}
                              </strong>
                            </p>
                          </li>
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">Waktu Selesai</span>
                              <strong className="">
                                {data.finished_at
                                  ? moment(data.finished_at).format("HH:mm z")
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                          <li className="col-sm-4">
                            <p>
                              <span className="text-soft">
                                Durasi Pengerjaan
                              </span>
                              <div>
                                <span className="badge badge-danger">
                                  {data.finished_at
                                    ? Math.ceil(
                                        moment(data.finished_at).diff(
                                          moment(data.started_at),
                                          "seconds"
                                        ) / 60
                                      ) + " menit"
                                    : "-"}
                                </span>
                              </div>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <button
                        className="btn btn-sm btn-block btn-primary mt-3"
                        disabled={!data.exam.setting.show_submission}
                        onClick={() => {
                          this.props.history.push(`/exam/${data.exam.id}/submission/${data.id}/review`)
                        }}
                      >
                        Lihat Pengerjaan Saya
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mb-2">
                <div className="card card-bordered card-full">
                  <div className="nk-help-plain card-inner text-center">
                    <div className="nk-help-img">
                      <img src={RankSVG} />
                    </div>
                    <div className="nk-help-text">
                      <h5>Nilai Kamu</h5>
                    </div>
                    <div className="nk-help-action">
                      <strong
                        className="text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        {data.exam.setting.show_score
                          ? prettyFloat(data.total_gained_points) +
                            " / " +
                            prettyFloat(data.total_points)
                          : "?"}
                      </strong>
                    </div>
                    {!data.exam.setting.show_score && (
                      <div className="nk-help-text text-soft text-center">
                        Dirahasiakan.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

index.propTypes = {};

const mapStateToProps = (state) => ({
  studentFirstName: state.auth.student.name.split(/[ ,]+/)[0],
});

export default connect(mapStateToProps)(withRouter(index));
