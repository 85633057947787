import React from "react";
import PropTypes from "prop-types";

const SimplePageHeader = ({ heading, message }) => (
  <div className="nk-block-head nk-block-head-lg">
    <div className="nk-block-between">
      <div className="nk-block-head-content">
        <h2 className="nk-block-title page-title">{heading}</h2>
        <div className="nk-block-des text-soft">
          <p>
            {message}
            <span className="text-primary">
              <em className="icon ni ni-info"></em>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
);

SimplePageHeader.propTypes = {};

export default SimplePageHeader;
