import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/Button";
import DocumentViewer from "components/Viewer/DocumentViewer";

export default class Document extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Button
        modalSize="xl"
        text={data.document.name || data.document.url}
        icon="files"
      >
        <Button.Modal>
          <div className="float-left mr-2">
            Jika dokumen <em>blank</em> coba tutup popup dan buka lagi. Atau
            unduh file.
            <span className="text-primary">
              <em className="icon ni ni-info"></em>
            </span>
          </div>
          <a href={data.document.url} target="_blank" className="btn btn-primary float-right" download>
            <em className="icon ni ni-download"></em> <span>Unduh</span>
          </a>

          <DocumentViewer
            url={data.document.url}
            extension={data.document.extension}
          />
        </Button.Modal>
      </Button>
    );
  }
}
