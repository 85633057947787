import React from "react";
import PropTypes from "prop-types";
import { EditorContext } from "pages/ExamAccomplishPage/SharedEditor";
import * as portals from "react-reverse-portal";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";
import { Html5Entities } from "html-entities";
import AnswerContentContainer from "./shared/AnswerContentContainer";

const decode = new Html5Entities().decode;

class Essay extends React.Component {
  static contextType = EditorContext;

  constructor(props) {
    super(props);
    this.debounceFn = null;
    this.state = { isEditorLoaded: false };
  }

  componentDidMount() {
    const timer = setInterval(() => {
      if (this.context.editor) {
        this.context.editor.setData(this.props.answer || "");
        this.setState({ isEditorLoaded: true });
        clearInterval(timer);
      }
    }, 300);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      this.state.isEditorLoaded &&
        this.context.editor.setData(this.props.answer || "");
    }

    if (
      prevProps.isPageLoaderShow === true &&
      this.props.isPageLoaderShow === false
    ) {
      this.saveAnswer();
    }
  }

  saveAnswer = () => {
    const essay =
      this.context.editor.getData().length > 0
        ? this.context.editor.getData()
        : null;
    this.props.saveAnswer(this.props.question.id, essay);
  };

  handleChange = () => {
    if (!this.debounceFn) {
      this.debounceFn = debounce(
        () => {
          this.saveAnswer();
        },
        1000,
        { leading: false, trailing: true }
      );
    }
    this.debounceFn();
  };

  cancelAnswer = () => {
    this.context.editor.setData("");
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    if (!this.state.isEditorLoaded) {
      return "Menunggu editor siap digunakan...";
    }

    const { question } = this.props;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{ __html: decode(question.essay.content) }}
          />
        </QuestionContentContainer>

        {this.context.editor ? (
          <AnswerContainer
            className="text-dark"
            style={{ paddingLeft: 0 }}
            onKeyUp={this.handleChange}
          >
            <AnswerContentContainer>
              <portals.OutPortal
                node={this.context.portalNode}
                onBlur={this.saveAnswer}
              />
            </AnswerContentContainer>
          </AnswerContainer>
        ) : (
          <div className="text-center">
            <SpinnerGrow variant="warning" />
          </div>
        )}

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Essay.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  answer: state.accomplish.studentAnswers[ownProps.question.id].question_answer,
  isPageLoaderShow: state.page.loader.isShow,
});

const EssayRedux = connect(mapStateToProps)(Essay);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <EssayRedux {...props} />
  </ErrorBoundary>
);
