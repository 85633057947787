import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Div = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #dbdfea !important;
  background: #f5f6fa;
  padding: 1rem;
  &:hover {
    background: #eaebee;
  }
`;

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => this.props.onClick();

  render() {
    const { section } = this.props;

    return (
      <Div className="card-inner" onClick={this.handleClick}>
        <h6>
          {section.title}
          <span
            className="text-soft"
            style={{
              fontSize: "70%",
              fontFamily:
                'Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
            }}
          >
            {" "}
            — {section.questions.length} soal
          </span>
        </h6>
        {/* {section.description && <p>{section.description}</p>} */}
      </Div>
    );
  }
}

SectionHeader.propTypes = {};

export default SectionHeader;
