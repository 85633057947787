import React from "react";
import PropTypes from "prop-types";
import GenerateQuestionList from "domain/ExamAccomplish/GenerateQuestionList";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import QuestionBox from "./QuestionBox";
import QuestionNavigator from "./QuestionNavigator";
import { connect } from "react-redux";
import { changeQuestion } from "redux/accomplish/action";
import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import BottomQuestionNavigator from "./BottomQuestionNavigator";

class AccomplishSection extends React.Component {
  static contextType = AccomplishContext;

  questionList = null;

  constructor(props) {
    super(props);

    this.questionList = GenerateQuestionList(this.props.data.sections);

    this.state = { currentQuestion: this.questionList[0] };
  }

  componentDidMount() {
    this.props.changeQuestion(this.state.currentQuestion); // tell redux
  }

  componentDidUpdate() {
    this.props.changeQuestion(this.state.currentQuestion); // tell redux
  }

  moveToPrevQuestion = () => {
    this.setState({ currentQuestion: this.state.currentQuestion.prev() });
  };

  moveToNextQuestion = () => {
    this.setState({ currentQuestion: this.state.currentQuestion.next() });
  };

  switchToQuestion = (absoluteIndex) => {
    this.setState({ currentQuestion: this.questionList[absoluteIndex] });
  };

  render() {
    const { data } = this.props;
    const { currentQuestion: question } = this.state;

    return (
      <div className="nk-block">
        <div className="card card-custom-s1 card-bordered">
          <div className="row no-gutters">
            <div
              className="col-lg-2"
              style={{ borderRight: "1px solid #dbdfea" }}
            >
              <LeftSidebar
                examInstruction={data.exam.instruction}
                totalQuestion={data.total_questions}
                remainingDurationInSecond={data.total_remaining_durations}
                totalDurations={data.total_durations}
              />
            </div>
            <div className="col-md-9 col-lg-7"  style={{minHeight: "500px"}}>
              <QuestionNavigator
                question={question}
                onNext={this.moveToNextQuestion}
                onPrev={this.moveToPrevQuestion}
              />
              <QuestionBox question={question} submit={this.context.submit} />
              <BottomQuestionNavigator
                question={question}
                onNext={this.moveToNextQuestion}
                onPrev={this.moveToPrevQuestion}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <RightSidebar
                onQuestionClicked={this.switchToQuestion}
                examSections={data.sections}
                questionList={this.questionList}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccomplishSection.propTypes = {};

export default connect(null, { changeQuestion })(AccomplishSection);
