import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  & a:active {
    margin-top: 3px;
  }
`;

const CancelAnswer = (props) => (
  <Container className="text-center mt-4">
    <a
      href="#"
      className="text-pink d-inline-block"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <em className="ni ni-cross"></em> <u>Batalkan Jawaban</u>
    </a>
  </Container>
);

CancelAnswer.propTypes = {};

export default CancelAnswer;
