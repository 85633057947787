import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 2px !important;
  padding-left: 5px;
  font-style: normal;
`;

/**
 * USAGE:
 *  <FormHint.Parent>
 *    No. telepon di awali
 *    <FormHint.Mark>+62</FormHint.Mark>
 *  </FormHint.Parent>
 */
const FormHint = {
  Parent: ({ children, className = "", ...props }) => (
    <Container className={"form-note text-soft " + className} {...props}>
      {children}
    </Container>
  ),
  Mark: (props) => <code>{props.children}</code>,
};

export default FormHint;
