import React from "react";
import AnswerBox from "./AnswerBox";
import styled from "styled-components";
import Row from "components/atoms/Grid/Row";
import Col from "components/atoms/Grid/Col";
import renderMath from "domain/Math/MathJax";
import { useEffect } from "react";
import { useRef } from "react";

const StatementContainer = styled.div`
  border-radius: 4px;
  border: 1px solid gray;
  padding: 0.5rem 1rem;

  & ol, & ul { all: revert }
`;

// const AvailableChoiceDropable = styled.div`
//   padding: 5px;
//   background: ${(props) => (props.isHover ? "#fef6e0" : "white")};
// `;

export default function MatchingBox({ statements, availableChoices }) {
  const ref = useRef();
  useEffect(() => {
    renderMath(() => ref.current); // we need this because we use "key" prop in parent component
  }, []);

  return (
    <div style={{ background: "white", padding: "0.5rem 1.25rem" }} ref={ref}>
      <Row className="gx-2 gy-2">
        <Col size={{ sm: 12, md: 12 }}>
          <Row className="gx-0 gy-2">
            {statements.map((stmt) => (
              <React.Fragment key={stmt.id}>
                <Col size={6}>
                  <StatementContainer
                    className="bg-light"
                    dangerouslySetInnerHTML={{
                      __html: stmt.content,
                    }}
                  />
                </Col>
                <Col size={1}>
                  <hr />
                </Col>
                <Col size={5}>
                  <AnswerBox
                    statement={stmt}
                    selectedChoice={stmt.choice}
                    availableChoices={availableChoices}
                    isFilled={stmt.choice !== null}
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Col>
        {/* <Col size={{ sm: 12, md: 12 }}>
          <Card isBordered className="mt-2 p-2">
            <h6>Pilihan Jawaban</h6>
            <p className="text-soft">
              Silakan tarik pilihan jawaban berikut ke kotak kosong di atas.
            </p>
            <AvailableChoiceDropable isHover={canDrop && isOver} ref={drop}>
              {availableChoices.map((choice) => (
                <ChoiceBox
                  className="mb-2"
                  key={choice.id}
                  item={{
                    answerId: null,
                    choiceId: choice.id,
                    type: ItemTypes.CHOICE_BOX,
                  }}
                  isMoved={choice.isMoved}
                  onDragEnd={(item, monitor) => {
                    const didDrop = monitor.didDrop();
                    if (!didDrop) {
                      moveStudentAnswerIntoAvailableChoice(choice.id);
                    }
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: choice.content,
                    }}
                  />
                </ChoiceBox>
              ))}
              <div style={{ clear: "both" }} />
            </AvailableChoiceDropable>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}
