import React from "react";
import { Switch, withRouter } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";

import Subscription from "./components/Layouts/Subscription";
// import Investment from "./components/Layouts/Investment";
import Blank from "./components/Layouts/Blank";

import ExamListPage from "./pages/ExamListPage";
import ExamAccomplishPage from "./pages/ExamAccomplishPage";
import ExamAccomplishResultPage from "./pages/ExamAccomplishResultPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import NoInternet from "components/Error/NoInternet";
const E_404 = lazyDelay(() => import("./pages/E_404"));
const PasswordResetRequestPage = lazyDelay(() =>
  import("./pages/PasswordResetRequestPage")
);
const PasswordResetVerifyPage = lazyDelay(() =>
  import("./pages/PasswordResetVerifyPage")
);
const PasswordResetSuccessPage = lazyDelay(() =>
  import("./pages/PasswordResetSuccessPage")
);
const ExamSubmissionListPage = lazyDelay(() =>
  import("./pages/ExamSubmissionListPage")
);
const ExamSubmissionReviewPage = lazyDelay(() =>
  import("./pages/ExamSubmissionReviewPage")
);
const PackageListPage = lazyDelay(() => import("./pages/PackageListPage"));
const TransactionListPage = lazyDelay(() =>
  import("./pages/TransactionListPage")
);
const ProfilePage = lazyDelay(() => import("./pages/ProfilePage"));
const LandingPage = lazyDelay(() => import("./pages/public/LandingPage"));
const PackageListPublicPage = lazyDelay(() =>
  import("./pages/public/PackageListPage")
);

const App = ({ history }) => {
  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <React.Suspense fallback={<PageFallback />}>
      <Switch>
        <AnyRoute
          path="/"
          exact
          component={LandingPage}
          title="EduExam: Aplikasi Ujian dari Edulogy"
        />
        <AnyRoute
          path="/p/packages"
          exact
          component={PackageListPublicPage}
          title="Paket Edulogy"
        />
        {/*
          NOTE: accomplish === submission perbedaannya accomplish adalah
          pengerjaan yang sedang dikerjakan
        */}
        <UserRoute
          path="/home"
          exact
          layout={Subscription}
          component={ExamListPage}
          title="Beranda"
        />
        {/* Route ini juga dipakai untuk resume ujian */}
        <UserRoute
          path="/exam/:exam/accomplish/:accomplish"
          exact
          layout={Blank}
          component={ExamAccomplishPage}
          title="Ujian Berlangsung"
        />
        <UserRoute
          path="/exam/:exam/submission"
          exact
          layout={Subscription}
          component={ExamSubmissionListPage}
          title="Riwayat Pengerjaan Ujian"
        />
        <UserRoute
          path="/exam/:exam/submission/:submission/result"
          exact
          layout={Subscription}
          component={ExamAccomplishResultPage}
          title="Hasil Ujian"
        />
        <UserRoute
          path="/exam/:exam/submission/:submission/review"
          exact
          layout={Blank}
          component={ExamSubmissionReviewPage}
          title="Pengerjaan Ujian Saya"
        />
        <UserRoute
          path="/package"
          exact
          layout={Subscription}
          component={PackageListPage}
          title="Paket Edulogy"
        />
        <UserRoute
          path="/transaction"
          exact
          layout={Subscription}
          component={TransactionListPage}
          title="Transaksi Saya"
        />
        <UserRoute
          path="/user"
          exact
          layout={Subscription}
          component={ProfilePage}
          title="Akun Saya"
        />
        <GuestRoute
          path="/p/login"
          exact
          component={LoginPage}
          title="Masuk ke EduExam"
        />
        <UserRoute
          path="/logout"
          exact
          component={LogoutPage}
          title="Logout..."
        />
        <GuestRoute
          path="/p/reset/request"
          exact
          component={PasswordResetRequestPage}
          title="Permintaan Reset Kata Sandi"
        />
        <GuestRoute
          path="/p/reset/verify"
          exact
          component={PasswordResetVerifyPage}
          title="Konfirmasi Reset Kata Sandi"
        />
        <GuestRoute
          path="/p/reset/success"
          exact
          component={PasswordResetSuccessPage}
          title="Berhasil Reset Kata Sandi"
        />

        <AnyRoute component={E_404} title="Halaman Tidak Ditemukan" />
      </Switch>
    </React.Suspense>
  );
};

const AppWithRouter = withRouter(App);

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <AppWithRouter {...props} />
  </ErrorBoundary>
);
