import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { Html5Entities } from "html-entities";
import { connect } from "react-redux";
import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";
import AnswerContentContainer from "./shared/AnswerContentContainer";

const decode = new Html5Entities().decode;

class Multichoice extends React.Component {
  static contextType = AccomplishContext;

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    const answerId = parseInt(e.target.value);
    this.props.saveAnswer(this.props.question.id, answerId);
  };

  cancelAnswer = () => {
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    const { question } = this.props;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: decode(question.multichoice.content),
            }}
          />
        </QuestionContentContainer>

        <AnswerContainer>
          {question.multichoice.answers.map((answer, index) => (
            <AnswerItemBordered key={index}>
              <Radio
                name="choice"
                value={answer.id}
                onChange={this.handleChange}
                checked={this.props.answerId === answer.id}
              >
                <AnswerContentContainer
                  dangerouslySetInnerHTML={{ __html: decode(answer.content) }}
                />
              </Radio>
            </AnswerItemBordered>
          ))}
        </AnswerContainer>

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Multichoice.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  answerId:
    state.accomplish.studentAnswers[ownProps.question.id].question_answer,
});

const MultichoiceRedux = connect(mapStateToProps)(Multichoice);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultichoiceRedux {...props} />
  </ErrorBoundary>
);
