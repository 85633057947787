import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SimpleModal from "components/Modal/SimpleModal";
import AnswerItemBordered from "../shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import CancelAnswer from "../shared/CancelAnswer";
import { Html5Entities } from "html-entities";
import { MatchingContext } from "../Matching";
import { useState } from "react";
import AnswerContentContainer from "../shared/AnswerContentContainer";

const decode = new Html5Entities().decode;

function ChoicesModal({
  statement,
  selectedChoice,
  availableChoices,
  onClose,
}) {
  const { handleSelect, cancelOneAnswer } = React.useContext(MatchingContext);
  const choices = selectedChoice
    ? [selectedChoice, ...availableChoices]
    : availableChoices;
  return (
    <SimpleModal title="Pilihan Jawaban" onClose={onClose}>
      <p className="text-soft">Silakan pilih jawaban yang tersedia:</p>
      {choices.map((choice) => (
        <AnswerItemBordered key={choice.id}>
          <Radio
            name="choice"
            value={choice.id}
            onChange={() => {
              handleSelect({ statementId: statement.id, choiceId: choice.id });
              onClose();
            }}
            checked={selectedChoice ? choice.id === selectedChoice.id : false}
          >
            <AnswerContentContainer dangerouslySetInnerHTML={{ __html: decode(choice.content) }} />
          </Radio>
        </AnswerItemBordered>
      ))}
      {selectedChoice && (
        <CancelAnswer
          onClick={() => {
            cancelOneAnswer(selectedChoice.id);
            onClose();
          }}
        />
      )}
    </SimpleModal>
  );
}

const Container = styled.div`
  color: ${(props) => (props.isHover ? "#ffa353" : "inherit")};
  background: ${(props) => (props.isHover ? "#fef6e0" : "white")};
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  border-radius: 4px;
  border: 1px dashed gray;
  border-style: ${(props) => (props.isFilled ? "solid" : "dashed")};
  padding: 0.5rem 1rem;
  /* margin-bottom: 1.5rem; */
  cursor: pointer;
  user-select: none;
  :hover {
    box-shadow: 1px 1px 5px #ddd;
  }

  & ol, & ul { all: revert }
`;

export default function AnswerBox({
  isFilled,
  statement,
  selectedChoice,
  availableChoices,
}) {
  const [isChoicesModalShow, setChoicesModalShow] = useState(false);
  return (
    <>
      {isChoicesModalShow && (
        <ChoicesModal
          statement={statement}
          selectedChoice={selectedChoice}
          availableChoices={availableChoices}
          onClose={() => setChoicesModalShow(false)}
        />
      )}

      <Container isFilled={isFilled} onClick={() => setChoicesModalShow(true)}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              statement.choice !== null
                ? decode(statement.choice.content)
                : "<small class='text-base'>Ketuk untuk pilih jawaban</small>",
          }}
        />
      </Container>
    </>
  );
}

AnswerBox.propTypes = {};
