import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";
import { Html5Entities } from "html-entities";

const decode = new Html5Entities().decode;

class Shortanswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { answer: this.props.answer };
    this.debounceFn = null;
  }

  handleChange = (e) => {
    this.setState({ answer: e.target.value }, () => {
      if (!this.debounceFn) {
        this.debounceFn = debounce(
          () => {
            this.props.saveAnswer(
              this.props.question.id,
              this.state.answer || null
            );
          },
          500,
          { leading: false, trailing: true }
        );
      }
      this.debounceFn();
    });
  };

  cancelAnswer = () => {
    this.setState({ answer: null });
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    const { question } = this.props;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: decode(question.content),
            }}
          />
        </QuestionContentContainer>

        <AnswerContainer className="text-dark" style={{ paddingLeft: 0 }}>
          <input
            type="text"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.answer || ""}
            placeholder="Isi jawaban kamu disini"
          />
        </AnswerContainer>

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Shortanswer.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  answer: state.accomplish.studentAnswers[ownProps.question.id].question_answer,
});

const ShortanswerRedux = connect(mapStateToProps)(Shortanswer);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <ShortanswerRedux {...props} />
  </ErrorBoundary>
);
