export const ACCOMPLISH_RESUMED = "ACCOMPLISH_RESUMED";

export const ACCOMPLISH_RESUME_REJECTED = "ACCOMPLISH_RESUME_REJECTED";

export const ACCOMPLISH_STARTED = "ACCOMPLISH_STARTED";

export const ACCOMPLISH_ENDED = "ACCOMPLISH_ENDED";

export const ACCOMPLISH_EXITED = "ACCOMPLISH_EXITED";

export const ACCOMPLISH_ANSWER_SAVED = "ACCOMPLISH_ANSWER_SAVED";

export const ACCOMPLISH_ANSWER_SAVED_IN_SERVER = "ACCOMPLISH_ANSWER_SAVED_IN_SERVER";

export const ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER = "ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER";

export const ACCOMPLISH_QUESTION_CHANGED = "ACCOMPLISH_QUESTION_CHANGED";
