import { LESSON_FETCHED, EXAM_FETCHED } from "./type";

export function lessons(state = null, action = {}) {
  switch (action.type) {
    case LESSON_FETCHED:
      return action.lessons;
    default:
      return state;
  }
}

export function exams(state = null, action = {}) {
  switch (action.type) {
    case EXAM_FETCHED:
      return action.exams;
    default:
      return state;
  }
}
