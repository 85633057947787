import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOGGED_IN_BY_GOOGLE,
  USER_GOOGLE_ATTACHED,
  USER_GOOGLE_DETACHED,
  USER_PROFILE_UPDATED,
  USER_PASSWORD_ADDED,
} from "./type";

function auth(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.auth;
    case USER_LOGGED_OUT:
      return {};
    case USER_LOGGED_IN_BY_GOOGLE:
      return action.auth;
    case USER_GOOGLE_ATTACHED:
      return {
        ...state,
        student: { ...state.student, has_google_account: true },
      };
    case USER_GOOGLE_DETACHED:
      return {
        ...state,
        student: { ...state.student, has_google_account: false },
      };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        student: {
          ...state.student,
          birth_place: action.birth_place,
          birth_at: action.birth_at,
          phone: action.phone,
          photo: action.photo || state.student.photo,
        },
      };
    case USER_PASSWORD_ADDED:
      return {
        ...state,
        student: {
          ...state.student,
          has_password: true,
        },
      };
    default:
      return state;
  }
}

export default auth;
