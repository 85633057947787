import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/Icon/Icon";
import prettyFloat from "domain/Float/PrettyFloat";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  /* background: #f8f8f8; */
  border: none;
  border-radius: initial;
  /* border-top: 1px solid #dbdfea; */
  /* border-color: #1c2b46; */
  /* height: 67.48px; */
`;

class BottomQuestionNavigator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { question } = this.props;

    return (
      <Container className="card card-bordered bg-dark">
        <div className="card-inner" style={{ padding: ".5rem" }}>
          <div className="align-center justify-content-center gx-3">
            {/* <div className="flex-item">
              <h6 style={{ marginBottom: 0 }}>
                Soal {question.absoluteIndex + 1}
              </h6>
              <div className="sub-text-sm mt-1">
                <span className="text-base mr-1">{prettyFloat(question.point)} poin</span>
                <span className="text-base mr-1">&middot;</span>
                <span className="text-base mr-1">{question.formated_type}</span>
                {question.type === "multianswer" && (
                  <>
                    <span className="text-base mr-1">&middot;</span>
                    <span className="text-base mr-1">
                      {question.multianswer.formated_scoring_mode}
                    </span>
                  </>
                )}
              </div>
            </div> */}
            <div className="flex-item">
              <button
                className="btn btn-light"
                style={{ padding: ".6375rem 1.325rem" }}
                disabled={question.prev() === undefined}
                onClick={this.props.onPrev}
              >
                <Icon nio="arrow-left" />
              </button>
            </div>
            <div className="flex-item">
              <button
                className="btn btn-light"
                style={{ padding: ".6375rem 1.325rem" }}
                disabled={question.next() === undefined}
                onClick={this.props.onNext}
              >
                <Icon nio="arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

BottomQuestionNavigator.propTypes = {};

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <BottomQuestionNavigator {...props} />
  </ErrorBoundary>
);
