import React from "react";
import PropTypes from "prop-types";
import BlueLogo from "assets/images/logo-blue.png";
import cx from "classnames";
import isEmpty from "validator/lib/isEmpty";
import { connect } from "react-redux";
import { login } from "redux/auth/action";
// import InlineError from "components/Error/InlineError";
import Alert from "components/Alert/Alert";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import { Link } from "react-router-dom";
import GoogleButton from "./GoogleButton";
import NioIcon from "components/atoms/Icon/NioIcon";
import { queryParams } from "renames";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        password: "",
      },
      // errors: { username: undefined, password: undefined },
      errors: null,
      loading: false,
      isShowPassword: false,
    };
  }

  handleLogin = (e) => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.props
        .login(this.state.data)
        .then(() => {
          const targetURL = decodeURIComponent(queryParams.parse(this.props.location.search).to || "");
          if (targetURL) {
            this.props.history.push(targetURL);
          } else {
            this.props.history.push("/home");
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: err.response.data ? err.response.data.message : null,
          });
        });
    }, 500);
  };

  handleChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: null,
    });

  togglePassword = (e) => {
    e.preventDefault();
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  render() {
    return (
      <div className="nk-app-root nk-body bg-white npc-general pg-auth">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-3 text-center">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={BlueLogo}
                      style={
                        {
                          opacity: 1,
                        } /* hapus ini kalau ingin support dark theme */
                      }
                      alt="logo"
                    />
                    {/* <img
                      className="logo-dark logo-img logo-img-lg"
                      src={WhiteLogo}
                      srcset="./logo-dark2x.png 2x"
                      alt="logo-dark"
                    /> */}
                  </Link>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">EduExam</h4>
                        <div className="nk-block-des">
                          <p>Portal ujian serentak sekolah.</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            EduCode atau Email
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            tabIndex="1"
                            className={cx("form-control", "form-control-lg", {
                              error: this.state.errors !== null,
                              // error: this.state.errors.username !== undefined,
                            })}
                            id="default-01"
                            name="username"
                            onChange={this.handleChange}
                            placeholder="Ketik EduCode atau Email"
                          />
                        </div>
                        {/* {this.state.errors.password && (
                          <InlineError
                            message={this.state.errors.password.message}
                          />
                        )} */}
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Kata Sandi
                          </label>
                          <Link
                            to="/p/reset/request"
                            className="link link-primary link-sm"
                          >
                            Lupa kata sandi?
                          </Link>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#"
                            onClick={this.togglePassword}
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em
                              className={cx({
                                "passcode-icon": true,
                                "icon-show": !this.state.isShowPassword,
                                icon: true,
                                ni: true,
                                "ni-eye": true,
                              })}
                            ></em>
                            <em
                              className={cx({
                                "passcode-icon": true,
                                "icon-show": this.state.isShowPassword,
                                icon: true,
                                ni: true,
                                "ni-eye-off": true,
                              })}
                            ></em>
                          </a>
                          <input
                            type={
                              this.state.isShowPassword ? "text" : "password"
                            }
                            tabIndex="2"
                            className={cx("form-control", "form-control-lg", {
                              // error: this.state.errors.password !== undefined,
                              error: this.state.errors !== null,
                            })}
                            id="password"
                            name="password"
                            onChange={this.handleChange}
                            placeholder="Ketik Kata Sandi"
                          />
                        </div>
                        {/* {this.state.errors.password && (
                          <InlineError
                            message={this.state.errors.password.message}
                          />
                        )} */}
                      </div>
                      {this.state.errors && (
                        <Alert warning>
                          <strong>Gagal login</strong>.<br />
                          Pastikan username dan password benar.
                        </Alert>
                      )}
                      <div className="form-group mb-2">
                        {this.state.loading ? (
                          <div className="text-center">
                            <SpinnerGrow variant="warning" />
                          </div>
                        ) : (
                          <button
                            tabIndex="3"
                            className="btn btn-lg btn-primary btn-block"
                            onClick={this.handleLogin}
                            disabled={
                              isEmpty(this.state.data.username) ||
                              isEmpty(this.state.data.password)
                            }
                          >
                            Masuk
                          </button>
                        )}
                      </div>
                      <div className="text-center mb-2">atau</div>
                      <GoogleButton />
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      {/* Gapai mimpimu. */}
                    </div>
                    {/* <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <ul className="nav justify-center gx-4">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Facebook
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Google
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-6 order-lg-last">
                      <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            target="_blank"
                            href="https://edulogy.id/kebijakan-privasi/"
                          >
                            Kebijakan Privasi
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            target="_blank"
                            href="https://go.edulogy.id/customer-care"
                          >
                            Customer Care
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-soft">
                          &copy; 2020 Edulogy. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {};

export default connect(null, { login })(Login);
