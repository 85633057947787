import React from "react";
import styled from "styled-components";
import ExamCard from "./ExamCard";
import { connect } from "react-redux";
import { fetchLessons, fetchExams } from "redux/examList/action";
import moment from "moment-timezone";
import isNull from "lodash/isNull";
import isArray from "lodash/isArray";
import EmptyImage from "assets/images/empty-box.png";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import InfoPopup from "./InfoPopup";
import Alert from "components/Alert/Alert";
import Row from "components/atoms/Grid/Row";
import Col from "components/atoms/Grid/Col";
import PageHeader from "components/molecules/Header/PageHeader";
import Block from "components/atoms/Block/Block";
import myAPI from "api/my";

// TODO: refactor
class ExamListPage extends React.Component {
  constructor(props) {
    super(props);
    const packageFilterFromRoute =
      this.props.location.state && this.props.location.state.packageFilter; // show exam list by provided packageId after redirect from other page
    /**
     * TODO: refactor
     * Datanya belum diambil: isLesseonFetched === false ketika data === null
     * Datanya sudah diambil & datanya kosong: isLesseonFetched === true ketika data === empty array
     * Datanya sudah diambil & datanya ada: isLesseonFetched === true ketika data === not empty array
     */
    this.state = {
      isLessonFetched: false,
      isExamFetched: false,
      filters: {
        school_lesson_id: null,
        status: null,
        exam_package_id: packageFilterFromRoute || null,
      },
      isPackageFetched: false,
      packages: [],
    };
  }

  componentDidMount() {
    this.fetchExams();
    this.props.fetchLessons();
    myAPI.getPurchasedExamPackage(this.props.studentId).then((packages) => {
      this.setState({ isPackageFetched: true, packages });
    });
  }

  componentDidUpdate() {
    const { lessons } = this.props;

    setTimeout(() => {
      if (!this.state.isLessonFetched && !isNull(lessons)) {
        this.setState({ isLessonFetched: true });
      }
    }, 500);
  }

  handleLessonFilterChanged = (e) => {
    e.persist();
    this.setState(
      (state) => ({
        isExamFetched: false,
        filters: { ...state.filters, school_lesson_id: e.target.value || null },
      }),
      this.fetchExams
    );
  };

  handleStatusFilterChanged = (e) => {
    e.persist();
    this.setState(
      (state) => ({
        isExamFetched: false,
        filters: { ...state.filters, status: e.target.value || null },
      }),
      this.fetchExams
    );
  };

  handlePackageFilterChanged = (e) => {
    e.persist();
    this.setState(
      (state) => ({
        isExamFetched: false,
        filters: { ...state.filters, exam_package_id: e.target.value || null },
      }),
      this.fetchExams
    );
  };

  fetchExams = () => {
    this.props
      .fetchExams(this.state.filters)
      .then(() => {
        setTimeout(() => this.setState({ isExamFetched: true }), 500);
      });
  };

  render() {
    const { exams, lessons } = this.props;
    const {
      isLessonFetched,
      isExamFetched,
      isPackageFetched,
      packages,
    } = this.state;

    let examCards = null;
    if (isExamFetched) {
      examCards =
        exams.length > 0 ? (
          exams.map((exam) => (
            <ExamCard
              key={exam.id}
              examId={exam.id}
              title={exam.title}
              type={exam.formated_short_type}
              lessonName={exam.school_lesson.name}
              startAt={exam.start_at}
              finishAt={exam.finish_at}
              duration={exam.total_durations}
              totalAttempt={exam.total_attempts}
              totalRemainingAttempt={
                exam.total_attempts - exam.total_submissions
              }
              isSecuredByToken={exam.setting_token_status}
              totalQuestion={exam.total_questions}
              status_accomplish={exam.status_accomplish}
              examPackage={exam.exam_package}
              school={exam.school}
            />
          ))
        ) : (
          <div className="text-center mt-2 mb-2 ml-n5">
            <img src={EmptyImage} style={{ width: "150px" }} />
            <h5 className="text-azure mt-4">
              <button className="btn btn-dim btn-outline-warning">
                TIDAK ADA UJIAN
              </button>
            </h5>
          </div>
        );
    }

    return (
      <>
        {window.MathJax === undefined && (
          <Alert danger>
            <div>
              <strong>
                Penampil Rumus belum termuat akibat internet tidak stabil.
              </strong>
              <br />
              Rumus tidak akan bisa ditampilkan di soal matematika atau fisika.
              Refresh halaman agar termuat.
            </div>
          </Alert>
        )}

        <PageHeader
          heading="Daftar Ujian"
          message="Kamu bisa mengerjakan ujian atau melihat riwayat."
        />

        <Block>
          <Row className="gx-2 gy-2">
            <Col size={{ sm: 6, md: 4 }}>
              <div className="form-control-wrap">
                {isLessonFetched ? (
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      onChange={this.handleLessonFilterChanged}
                    >
                      <option value="">Semua pelajaran</option>
                      {isArray(lessons) &&
                        lessons.map((lesson) => (
                          <option key={lesson.id} value={lesson.id}>
                            {lesson.name}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : (
                  <div className="text-center">
                    <SpinnerGrow variant="warning" />
                  </div>
                )}
              </div>
            </Col>
            <Col size={{ sm: 6, md: 4 }}>
              <div className="form-control-wrap">
                {isLessonFetched ? (
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      onChange={this.handleStatusFilterChanged}
                    >
                      <option value="">Semua status</option>
                      <option selected value="active">Bisa Dikerjakan</option>
                      <option value="inactive">Tak Bisa Dikerjakan</option>
                    </select>
                  </div>
                ) : (
                  <div className="text-center">
                    <SpinnerGrow variant="warning" />
                  </div>
                )}
              </div>
            </Col>
            <Col size={{ sm: 12, md: 4 }}>
              <div className="form-control-wrap">
                {isPackageFetched ? (
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      defaultValue={this.state.filters.exam_package_id}
                      onChange={this.handlePackageFilterChanged}
                    >
                      <option value="">Semua paket saya</option>
                      {packages.map(({ id, name }) => (
                        <option value={id}>{name}</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="text-center">
                    <SpinnerGrow variant="warning" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Block>

        <div className="nk-block">
          {isExamFetched ? (
            examCards
          ) : (
            <div className="text-center" style={{ marginTop: "80px" }}>
              <SpinnerGrow variant="warning" size="3rem" />
            </div>
          )}
        </div>

        <InfoPopup />
      </>
    );
  }
}

ExamListPage.propTypes = {};

function mapStateToProps(state) {
  return {
    lessons: state.examLessons,
    exams: state.exams,
    studentId: state.auth.student.id,
  };
}

export default connect(mapStateToProps, {
  fetchLessons,
  fetchExams,
})(ExamListPage);
