import { combineReducers } from 'redux';
import auth from './auth/reducer';
import { lessons as examLessons, exams } from './examList/reducer';
import accomplish from './accomplish/reducer';
import page from './page/reducer';
import { submission } from './submission/reducer';
import submissionReview from "./submissionReview/reducer";

const appReducer = combineReducers({
    auth,
    examLessons,
    exams,
    accomplish,
    submission,
    submissionReview,
    page,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
