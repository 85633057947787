import React from "react";
import PropTypes from "prop-types";
import SimpleModal from "components/Modal/SimpleModal";
import Checkbox from "components/Form/Checkbox";
import { connect } from "react-redux";
import ErrorBoundary from "components/Error/ErrorBoundary";

class InfoPopup extends React.Component {
  constructor(props) {
    super(props);

    this.dontShowAgainKeyName = `${this.props.studentId}_dont_show_again_info_popup`;
    this.state = { isShowPopup: false };
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          isShowPopup:
            localStorage.getItem(this.dontShowAgainKeyName) !== "true",
        }),
      800
    );
  }

  handleChange = (e) => {
    if (e.target.checked) {
      localStorage.setItem(this.dontShowAgainKeyName, "true");
    } else {
      localStorage.removeItem(this.dontShowAgainKeyName);
    }
  };

  handeClick = () => this.setState({ isShowPopup: false });

  render() {
    if (!this.state.isShowPopup) {
      return <div className="infoPopup"></div>;
    }

    return (
      <SimpleModal>
        <h5 className="text-primary mb-3">Berkenalan dengan Sistem lebih Dekat</h5>
        <ul className="list list-lg list-checked-circle list-success">
          <li>
            <strong>Lanjutkan ujian jika keluar tiba-tiba</strong> – Kamu bisa
            membuka halaman Riwayat untuk melanjutkan ujian. Namun ingat waktu tetap berjalan.
          </li>
          <li>
            <strong>Jawaban tersimpan di server</strong> – Setiap kali kamu
            menjawab sistem akan berusaha menyimpannya di server. Gunanya ketika
            kamu mengalami <em>crash</em>&nbsp; jawaban-jawabanmu tidak akan
            hilang.
          </li>
          <li>
            <strong>
              Tombol kirim ulang muncul ketika gagal menyimpan di server
            </strong>{" "}
            – Bila gagal menyimpan kami akan munculkan peringatan dan tombol
            kirim ulang.{" "}
            <span className="d-block mt-1 badge badge-danger badge-dim text-wrap">
              Peringatan: jangan <b>refresh/close browser</b> jika jawabanmu
              belum tersimpan di server.
            </span>
          </li>
        </ul>
        <div className="mt-3 mb-2 ml-1" style={{ fontSize: "14px" }}>
          <Checkbox name="choice" onChange={this.handleChange}>
            Jangan tampilkan lagi pesan ini.
          </Checkbox>
        </div>
        <button
          type="button"
          className="btn btn-block btn-primary"
          onClick={this.handeClick}
        >
          Saya Mengerti
        </button>
      </SimpleModal>
    );
  }
}

InfoPopup.propTypes = {};

const mapStateToProps = (state) => ({
  studentId: state.auth.student.id,
});

const InfoPopupRedux = connect(mapStateToProps)(InfoPopup);

export default (props) => (
  <ErrorBoundary fallback={<span></span>}>
    <InfoPopupRedux {...props} />
  </ErrorBoundary>
);
