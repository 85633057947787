import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "../variables";

export const SCHOOLS = [
  {
    id: 1,
    name: "EduSchool",
  },
  {
    id: 2,
    name: "SMAN 27",
  },
];

export default [
  rest.get(`${BASE_URL}/v1/school`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: SCHOOLS,
      })
    );
  }),
];
