import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Checkbox from "components/Form/Checkbox";
import { Html5Entities } from "html-entities";
import { connect } from "react-redux";
import ArrayWithout from "lodash/without";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";
import AnswerContentContainer from "./shared/AnswerContentContainer";

const decode = new Html5Entities().decode;

class Multianswer extends React.Component {
  constructor(props) {
    super(props);
  }

  saveAnswer = (newAnswerIds) => {
    newAnswerIds = newAnswerIds.length > 0 ? newAnswerIds : null;
    this.props.saveAnswer(this.props.question.id, newAnswerIds);
  };

  handleChange = (e) => {
    const answerIdFromCheckbox = parseInt(e.target.value);
    if (!e.target.checked) {
      this.saveAnswer(ArrayWithout(this.props.answerIds, answerIdFromCheckbox));
    } else {
      this.saveAnswer(this.props.answerIds.concat([answerIdFromCheckbox]));
    }
  };

  cancelAnswer = () => {
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    const { question } = this.props;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: decode(question.multianswer.content),
            }}
          />
        </QuestionContentContainer>

        <AnswerContainer>
          {question.multianswer.answers.map((answer, index) => (
            <AnswerItemBordered key={index}>
              <Checkbox
                name="choice"
                value={answer.id}
                onChange={this.handleChange}
                checked={this.props.answerIds.includes(answer.id)}
              >
                <AnswerContentContainer
                  dangerouslySetInnerHTML={{ __html: decode(answer.content) }}
                />
              </Checkbox>
            </AnswerItemBordered>
          ))}
        </AnswerContainer>

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Multianswer.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    answerIds:
      state.accomplish.studentAnswers[ownProps.question.id].question_answer ||
      [],
  };
};

const MultianswerRedux = connect(mapStateToProps)(Multianswer);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <MultianswerRedux {...props} />
  </ErrorBoundary>
);
