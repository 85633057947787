import React from "react";
import PropTypes from "prop-types";

/**
 * <OutsideAlerter when={boolean} onClickOutside={your_handler} />
 *
 * @param when            aktifkan click-outside listener hanya ketika when = true
 * @param onClickOutside
 */
class ClickOutsideAlerter extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.props.when) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleClickOutside = (e) => {
    if (!this.wrapperRef.current.contains(e.target)) {
      this.props.onClickOutside();
    }
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        style={{ display: "inline-block" }}
        className={this.props.when ? "active" : "" /* cuma agar re-render */}
      >
        {this.props.children}
      </div>
    );
  }
}

ClickOutsideAlerter.propTypes = {};

export default ClickOutsideAlerter;
