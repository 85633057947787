import React from "react";
import PropTypes from "prop-types";
import BlueLogo from "assets/images/logo-blue.png";
import HeaderSVG from "./header.svg";
import AccomplishContext from "pages/ExamAccomplishPage/AccomplishContext";
import { connect } from "react-redux";
import values from "lodash/values";
import ConfirmModal from "components/Modal/ConfirmModal";

class Header extends React.Component {
  static contextType = AccomplishContext;

  constructor(props) {
    super(props);
    this.state = { isDoneConfirmShow: false }
  }

  submit = (e) => {
    e.preventDefault();
    this.context.submit();
  };

  openDoneConfirm = (e) => {
    e.preventDefault();
    this.setState({ isDoneConfirmShow: true });
  };
  closeDoneConfirm = () => {
    this.setState({ isDoneConfirmShow: false });
  };

  render() {
    const { examTitle, examDescription } = this.props;

    return (
      <>
        <div className="nk-block text-center">
          <img
            className="logo-light logo-img logo-img-lg"
            src={BlueLogo}
            style={{ opacity: 1, marginLeft: "-10px" }}
            alt="logo"
          />
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner">
              <div className="nk-help">
                <div className="nk-help-img">
                  <img src={HeaderSVG} />
                </div>
                <div className="nk-help-text">
                  <h5>{examTitle}</h5>
                  <p className="text-soft">
                    {examDescription ? (
                      examDescription.trim()
                    ) : (
                      <em>Tidak ada deskripsi.</em>
                    )}
                  </p>
                </div>
                <div className="nk-help-action">
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-primary"
                    onClick={this.openDoneConfirm}
                    disabled={!this.props.hasStudentAnswer}
                  >
                    Saya Sudah Selesai Ujian
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isDoneConfirmShow && (
          <ConfirmModal
            variant="danger"
            acceptLabel="Saya Yakin"
            onAccept={this.submit}
            onCancel={this.closeDoneConfirm}
            message={
              <>
                <strong className="text-center">Konfirmasi Selesai</strong>
                <br />
                Apakah kamu yakin akan menyelesaikan ujian ini?
              </>
            }
          />
        )}
      </>
    );
  }
}

Header.propTypes = {};

const mapStateToProps = (state) => ({
  hasStudentAnswer: values(state.accomplish.studentAnswers).filter(
    (studentAnswer) => studentAnswer.question_answer !== null
  ).length > 0,
});

export default connect(mapStateToProps)(Header);
