import React from "react";
import PropTypes from "prop-types";
import QuestionContentContainer from "./shared/QuestionContentContainer";
import AnswerContainer from "./shared/AnswerContainer";
import AnswerItemBordered from "./shared/AnswerItemBordered";
import Radio from "components/Form/Radio";
import { Html5Entities } from "html-entities";
import { connect } from "react-redux";
import CancelAnswer from "./shared/CancelAnswer";
import ErrorBoundary from "components/Error/ErrorBoundary";

const decode = new Html5Entities().decode;

class Truefalse extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    this.props.saveAnswer(this.props.question.id, e.target.value);
  };

  cancelAnswer = () => {
    this.props.saveAnswer(this.props.question.id, null);
  };

  render() {
    const { question } = this.props;

    return (
      <>
        <QuestionContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: decode(question.truefalse.content),
            }}
          />
        </QuestionContentContainer>

        <AnswerContainer>
          <AnswerItemBordered>
            <Radio
              name="choice"
              value="true"
              onChange={this.handleChange}
              checked={this.props.answer === "true"}
            >
              Benar
            </Radio>
          </AnswerItemBordered>
          <AnswerItemBordered>
            <Radio
              name="choice"
              value="false"
              onChange={this.handleChange}
              checked={this.props.answer === "false"}
            >
              Salah
            </Radio>
          </AnswerItemBordered>
        </AnswerContainer>

        <CancelAnswer onClick={this.cancelAnswer} />
      </>
    );
  }
}

Truefalse.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  answer: state.accomplish.studentAnswers[ownProps.question.id].question_answer,
});

const TruefalseRedux = connect(mapStateToProps)(Truefalse);

export default (props) => (
  <ErrorBoundary
    fallback={<em className="text-warning">Oops terjadi kesalahan...</em>}
  >
    <TruefalseRedux {...props} />
  </ErrorBoundary>
);
