import initStudentAnswers from "domain/ExamAccomplish/InitStudentAnswers";
import extractStudentAnswers from "domain/ExamAccomplish/ExtractStudentAnswers";
import initAnswersSavedInServerState from "domain/ExamAccomplish/InitAnswersSavedInServerState";
import {
  ACCOMPLISH_RESUMED,
  ACCOMPLISH_RESUME_REJECTED,
  ACCOMPLISH_STARTED,
  ACCOMPLISH_ENDED,
  ACCOMPLISH_EXITED,
  ACCOMPLISH_ANSWER_SAVED,
  ACCOMPLISH_ANSWER_SAVED_IN_SERVER,
  ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER,
  ACCOMPLISH_QUESTION_CHANGED,
} from "./type";

const initialState = {
  data: {},
  currentQuestion: null,
  studentAnswers: {},
  isAnswersSavedInServer: {},
};

function accomplish(state = initialState, action) {
  switch (action.type) {
    case ACCOMPLISH_STARTED:
      return {
        ...state,
        data: action.data,
        studentAnswers: initStudentAnswers(action.data.sections),
        isAnswersSavedInServer: initAnswersSavedInServerState(
          action.data.sections
        ),
      };
    case ACCOMPLISH_RESUMED:
      return {
        ...state,
        data: action.data,
        studentAnswers: extractStudentAnswers(action.data.sections),
        isAnswersSavedInServer: initAnswersSavedInServerState(
          action.data.sections
        ),
      };
    case ACCOMPLISH_RESUME_REJECTED:
      return {};
    case ACCOMPLISH_ENDED:
      return {};
    case ACCOMPLISH_EXITED:
      return {};
    case ACCOMPLISH_ANSWER_SAVED:
      return {
        ...state,
        studentAnswers: {
          ...state.studentAnswers,
          [action.answer.question_id]: action.answer,
        },
      };
    case ACCOMPLISH_ANSWER_SAVED_IN_SERVER:
      return {
        ...state,
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [action.questionId]: true,
        },
      };
    case ACCOMPLISH_ANSWER_FAILED_SAVE_IN_SERVER:
      return {
        ...state,
        isAnswersSavedInServer: {
          ...state.isAnswersSavedInServer,
          [action.questionId]: false,
        },
      };
    case ACCOMPLISH_QUESTION_CHANGED:
      return {
        ...state,
        currentQuestion: action.question,
      };
    default:
      return state;
  }
}

export default accomplish;
