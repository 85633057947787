import React from "react";
import PropTypes from "prop-types";
import Button from "components/atoms/Button/Button";
import NioIcon from "components/atoms/Icon/NioIcon";

export default function IconButton({
  iconLeft,
  iconRight,
  children,
  ...restProps
}) {
  if (!iconLeft && !iconRight) throw new Error("No icon provided");
  return (
    <Button
      renderLeft={() => iconLeft && <NioIcon name={iconLeft} />}
      renderRight={() => iconRight && <NioIcon name={iconRight} />}
      {...restProps}
    >
      {Boolean(children) && <span>{children}</span>}
    </Button>
  );
}

IconButton.propTypes = {
  /**
   * Icon left.
   */
  iconLeft: PropTypes.string,
  /**
   * Icon right.
   */
  iconRight: PropTypes.string,
  /**
   * Children.
   */
  children: PropTypes.node,
};
