import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert/Alert";
import Icon from "components/Icon/Icon";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import ErrorBoundary from "components/Error/ErrorBoundary";

class FailAlertWhenSaveAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { isLoading: false };
  }

  componentDidMount() {
    window.scrollTo(
      0,
      this.ref.current.getBoundingClientRect().top + window.scrollY - 60
    );
  }

  componentDidUpdate() {
    window.scrollTo(
      0,
      this.ref.current.getBoundingClientRect().top + window.scrollY - 60
    );
  }

  handleResend = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.onResend().catch(() => this.setState({ isLoading: false }));
    }, 300);
  };

  render() {
    return (
      <Alert warning>
        <strong ref={this.ref}>
          Gagal menyimpan {this.props.total} jawaban soal.
        </strong>
        <div className="mt-1">
          Periksa koneksi kamu kemudian
          <br />
          {!this.state.isLoading ? (
            <button
              type="button"
              className="btn btn-sm btn-dim btn-outline-primary d-block mt-1"
              onClick={this.handleResend}
            >
              <Icon nio="redo" /> <span>Coba lagi</span>
            </button>
          ) : (
            <div className="text-left ml-2 mt-1">
              <SpinnerGrow variant="warning" />
            </div>
          )}
        </div>
      </Alert>
    );
  }
}

FailAlertWhenSaveAnswer.propTypes = {};

export default (props) => (
  <ErrorBoundary
    fallback={
      <div className="p-2 bg-warning text-white">Jawaban gagal disimpan.</div>
    }
  >
    <FailAlertWhenSaveAnswer {...props} />
  </ErrorBoundary>
);
