import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const NioIcon = ({ name, className = null, color = null, ...props }) => (
  <em
    className={cx(
      className,
      "icon",
      "ni",
      "ni-" + name,
      color && "text-" + color
    )}
    {...props}
  />
);

NioIcon.propTypes = {
  /**
   * Nio icon name
   */
  name: PropTypes.string.isRequired,
  /**
   * Classname to add
   */
  className: PropTypes.string,
  /**
   * Color of icon
   */
  color: PropTypes.string,
};

export default NioIcon;
