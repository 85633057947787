import React from "react";
import PropTypes from "prop-types";
import GenerateRandomString from "domain/String/GenerateRandomString";
import styled from "styled-components";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    this.props.labelRef && this.props.labelRef(this.labelRef);
  }

  render() {
    const id = GenerateRandomString();

    const { children, labelRef, ...rest } = this.props;

    return (
      <div className="custom-control custom-control-sm custom-checkbox">
        <input
          type="checkbox"
          name={this.props.name}
          className="custom-control-input"
          id={"choice" + id}
          {...rest}
        />
        <label ref={this.labelRef} className="custom-control-label" htmlFor={"choice" + id}>
          {children}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {};

export default Checkbox;
