export const USER_LOGGED_IN = 'USER_LOGGED_IN';

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const USER_LOGGED_IN_BY_GOOGLE = 'USER_LOGGED_IN_BY_GOOGLE';

export const USER_GOOGLE_ATTACHED = 'USER_GOOGLE_ATTACHED';

export const USER_GOOGLE_DETACHED = 'USER_GOOGLE_DETACHED';

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';

export const USER_PASSWORD_ADDED = 'USER_PASSWORD_ADDED';
