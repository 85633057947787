import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOGGED_IN_BY_GOOGLE,
  USER_GOOGLE_ATTACHED,
  USER_GOOGLE_DETACHED,
  USER_PROFILE_UPDATED,
  USER_PASSWORD_ADDED,
} from "./type";
import api from "api";

export const userLoggedIn = (auth) => ({
  type: USER_LOGGED_IN,
  auth,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const userLoggedInByGoogle = (auth) => ({
  type: USER_LOGGED_IN_BY_GOOGLE,
  auth,
});

export const userGoogleAttached = () => ({
  type: USER_GOOGLE_ATTACHED,
});

export const userGoogleDettached = () => ({
  type: USER_GOOGLE_DETACHED,
});

export const userProfileUpdated = ({
  birth_place,
  birth_at,
  phone,
  photo,
}) => ({
  type: USER_PROFILE_UPDATED,
  birth_place,
  birth_at,
  phone,
  photo,
});

export const passwordAdded = () => ({
  type: USER_PASSWORD_ADDED,
});

export const login = (credentials) => {
  return (dispatch) =>
    api.user.login(credentials).then((auth) => {
      localStorage.setItem("auth", JSON.stringify(auth));
      dispatch(userLoggedIn(auth));
    });
};

export const logout = (accessToken) => {
  return (dispatch) =>
    api.user.logout(accessToken).then(() => {
      localStorage.removeItem("auth");
      dispatch(userLoggedOut());
    });
};

export const loginGoogle = (auth) => {
  return (dispatch) => {
    localStorage.setItem("auth", JSON.stringify(auth));
    dispatch(userLoggedInByGoogle(auth));
  };
};

export const markGoogleAttached = () => {
  return (dispatch) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        student: { ...auth.student, has_google_account: true },
      })
    );
    dispatch(userGoogleAttached());
  };
};

export const markGoogleDettached = () => {
  return (dispatch) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        student: { ...auth.student, has_google_account: false },
      })
    );
    dispatch(userGoogleDettached());
  };
};

export const updateProfile = ({ birth_place, birth_at, phone, photo }) => {
  const newProperties = { birth_place, birth_at, phone, photo };
  return (dispatch) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        student: { ...auth.student, ...newProperties },
      })
    );
    dispatch(userProfileUpdated(newProperties));
  };
};

export const markPasswordAdded = () => {
  return (dispatch) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        student: { ...auth.student, has_password: true },
      })
    );
    dispatch(passwordAdded());
  };
};
