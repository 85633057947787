import React from "react";
import PropTypes from "prop-types";
import truncate from "domain/String/Truncate";
import SimpleModal from "components/Modal/SimpleModal";

export default class AttachmentButton extends React.Component {
  static Modal = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = { isModalShow: false };
  }

  showModal = () => this.setState({ isModalShow: true });
  closeModal = () => this.setState({ isModalShow: false });

  render() {
    const { text, icon, children, useModal = true, modalSize } = this.props;
    const MAX_BUTTON_TEXT_LENGTH = 25;
    const MAX_TITLE_LENGTH = 50;
    return (
      <>
        {useModal && this.state.isModalShow && (
          <SimpleModal
            size={modalSize}
            title={
              <>
                <span className="text-soft">Lampiran:</span>{" "}
                {truncate(text, MAX_TITLE_LENGTH)}
              </>
            }
            onClose={this.closeModal}
          >
            {children}
          </SimpleModal>
        )}

        <button
          type="button"
          onClick={this.showModal}
          className="btn btn-sm btn-dim btn-outline-info mr-1 mb-1"
        >
          <em className={"icon ni ni-" + icon}></em>
          <span>{truncate(text, MAX_BUTTON_TEXT_LENGTH)}</span>
        </button>
      </>
    );
  }
}
