import produce from "immer";
import {
  SUBMISSION_REVIEW_FETCHED,
  SUBMISSION_REVIEW_QUESTION_SWITCHED,
} from "./type";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";
import { mapValues, values } from "lodash";

const initialAnswer = {
  multichoice: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  multianswer: {
    uuid: null,
    id: null,
    questionUUID: null,
    key: false,
    content: null,
  },
  truefalse: {
    uuid: null,
    questionUUID: null,
    answer: null,
  },
  essay: {
    uuid: null,
    questionUUID: null,
    content: null,
  },
  shortanswer: {
    uuid: null,
    questionUUID: null,
    content: null,
  },
  matching: {
    uuid: null,
    questionUUID: null,
    id: null,
    content: null,
    student_se_sect_q_answer_id: null,
  },
};

const initialState = {
  currentQuestion: { sectionId: null, uuid: null },
  sectionOrder: [],
  questionOrder: {},
  answerOrder: {},
  data: {
    submission: {
      id: null,
      total_questions: null,
      total_filleds: null,
      score: null,
    },
    exam: {},
    sections: [],
    questions: {},
    answers: {},
  },
};

export default produce((state, action = {}) => {
  switch (action.type) {
    case SUBMISSION_REVIEW_FETCHED: {
      const questionsFlatten = flatten(
        action.data.sections.map((section) =>
          section.questions.map((question) =>
            omit({
              ...question,
              uuid: uuidv4(),
              sectionId: section.id,
            })
          )
        )
      );
      const questions = questionsFlatten.reduce(
        (accumulator, question) => ({
          ...accumulator,
          [question.uuid]: question,
        }),
        {}
      );

      let answersGroupedByQuestion = questionsFlatten
        .filter(({ type }) => type === "multichoice")
        .reduce(
          (accumulator, question) => ({
            ...accumulator,
            [question.uuid]: question.multichoice.answers.map((answer) => ({
              ...initialAnswer[question.type],
              ...answer,
              uuid: uuidv4(),
              questionUUID: question.uuid,
            })),
          }),
          {}
        );
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(
            ({ type }) => type === "multianswer"
          )
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: question.multianswer.answers.map((answer) => ({
                ...initialAnswer[question.type],
                ...answer,
                uuid: uuidv4(),
                questionUUID: question.uuid,
              })),
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "truefalse")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.truefalse.answer.id,
                  status: question.truefalse.answer.status,
                },
              ],
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "essay")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: [
                {
                  ...initialAnswer[question.type],
                  uuid: uuidv4(),
                  questionUUID: question.uuid,
                  id: question.essay.answer.id,
                  content: question.essay.answer.content,
                },
              ],
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "shortanswer")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: question.answers.map((answer) => ({
                ...initialAnswer[question.type],
                ...answer,
                uuid: uuidv4(),
                questionUUID: question.uuid,
              })),
            }),
            {}
          ),
      };
      answersGroupedByQuestion = {
        ...answersGroupedByQuestion,
        ...questionsFlatten
          .filter(({ type }) => type === "matching")
          .reduce(
            (accumulator, question) => ({
              ...accumulator,
              [question.uuid]: question.statements.map((stmt) => ({ // note jangan pusing statements = answers dan answers != answer dari JSON backend
                ...initialAnswer[question.type],
                ...stmt,
                uuid: uuidv4(),
                questionUUID: question.uuid,
              })),
            }),
            {}
          ),
      };

      const sectionOrder = action.data.sections.map(({ id }) => id);
      const questionOrder = action.data.sections.reduce(
        (accumulator, section) => ({
          ...accumulator,
          [section.id]: questionsFlatten
            .filter(({ sectionId }) => sectionId === section.id)
            .map(({ uuid }) => uuid),
        }),
        {}
      );

      const firstQuestionUUID = sectionOrder.reduce(
        (accumulator, sectionId) => [
          ...accumulator,
          ...questionOrder[sectionId],
        ],
        []
      )[0];

      return {
        currentQuestion: {
          sectionId: firstQuestionUUID
            ? questions[firstQuestionUUID].sectionId
            : sectionOrder[0],
          uuid: firstQuestionUUID || null,
        },
        answerOrder: mapValues(answersGroupedByQuestion, (answers) =>
          answers.map((answer) => answer.uuid)
        ),
        sectionOrder,
        questionOrder,
        data: {
          submission: {
            id: action.data.id,
            total_questions: action.data.total_questions,
            total_filleds: action.data.total_filleds,
            total_gained_points: action.data.total_gained_points,
            settings: action.data.setting,
          },
          exam: { ...action.data.exam },
          sections: action.data.sections.reduce(
            (accumulator, section) => ({
              ...accumulator,
              [section.id]: omit(section, "questions"),
            }),
            {}
          ),
          questions,
          answers: flatten(values(answersGroupedByQuestion)).reduce(
            (accumulator, answer) => ({
              ...accumulator,
              [answer.uuid]: { ...answer },
            }),
            {}
          ),
        },
      };
    }
    case SUBMISSION_REVIEW_QUESTION_SWITCHED: {
      state.currentQuestion = {
        sectionId: action.sectionId,
        uuid: action.uuid,
      };
      return;
    }
    default:
      return state;
  }
}, initialState);
